import { createAction, props } from '@ngrx/store';
import { SystemUser } from '../../models/system-user';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',
  LOGOUT_FAILURE = '[Auth] Logout Failure',
}

export const LogIn = createAction(
  AuthActionTypes.LOGIN,
  props<{ email: string; password: string }>()
);

export const LogInSuccess = createAction(
  AuthActionTypes.LOGIN_SUCCESS,
  props<{ user: SystemUser }>()
);

export const LogInFailure = createAction(
  AuthActionTypes.LOGIN_FAILURE,
  props<{ error: string }>()
);

export const LogOut = createAction(AuthActionTypes.LOGOUT);

export const LogOutSuccess = createAction(AuthActionTypes.LOGOUT_SUCCESS);

export const LogOutFailure = createAction(
  AuthActionTypes.LOGOUT_FAILURE,
  props<{ error: string }>()
);
