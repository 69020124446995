//gender
export const gender: any[] = [
  { id: 1, name: "male" },
  { id: 2, name: "female" }]

export const langs: any = [
    { id: 1, name: "English" }, 
    { id: 2, name: "日本語" },
    { id: 3, name: "Việt Nam" }
  ];

export const languages: Array<string> = [
    "en","jp", "vn"
  ];


