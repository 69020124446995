import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DevicesState } from './device.reducers';

export const devicesKey = 'devices';
export const selectDevices = createFeatureSelector<DevicesState>(devicesKey);

export const selectAllDevices = createSelector(
  selectDevices,
  (state: DevicesState) => state.devices
);

export const getSelectedDevice = createSelector(
  selectDevices,
  (state: any) => state.devices.selectedDevice
);

// export const getDeviceById = createSelector(
//   selectDevices,
//   (state: DevicesState, { deviceId }) =>
//     state.devices.find((device: Device) => device.id === deviceId)
// );

export const isShowDeviceName = createSelector(
  selectDevices,
  (state: any) => state.devices.isShowName
);

//errorMessage
export const responseErrorMessage = createSelector(
  selectDevices,
  (state: any) => state?.devices.errorMessage
);