// toast.service.ts
import { Injectable, TemplateRef  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];


  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  close(){
    this.toasts.pop();
  }

  // Callback method to remove Toast DOM element from view
  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showError(msgError: string) {
    this.close();

    this.show(msgError, {
      classname: 'bs-toast toast bg-danger ld ld-fall-rtl-in',
      delay: 2000 ,
      autohide: false,
    });
  }

  showSuccess(msgSuccess: string) {
    this.close();

    this.show(msgSuccess, {
      classname: 'bs-toast toast bg-success ld ld-fall-rtl-in',
      delay: 3000 ,
      autohide: true,
    });
  }

  showWarning(msgWarning: string) {
    this.close();
    
    this.show(msgWarning, {
      classname: 'bs-toast toast bg-warning ld ld-fall-rtl-in',
      delay: 5000 ,
      autohide: true,
    });
  }
}
