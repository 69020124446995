import { FeaturePermission } from "@modules/permission/models/feature-permission.model";
import { Features } from "@modules/permission/models/features.enum";
import { Permission } from "@modules/permission/models/permissions.enum";

export class SystemUser {
  id?: number;
  name?: string;
  email?: string;
  readonly?: boolean;
  administrator?: boolean;
  permission: string;
  timezone: string = "Asia/Tokyo";
  company_id: number;

  constructor(info?: any) {
    const { id, email, administrator, name, readonly, permission,timezone,company_id} = info;
    this.id = id;
    this.name = name;
    this.email = email;
    this.readonly = readonly;
    this.administrator = administrator;
    this.permission = permission;
    this.timezone = timezone;
    this.company_id = company_id;
  }
}
