import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { capitalizeString } from "@common/shared/utils/text.utils";
import { select, Store } from "@ngrx/store";
import {
  getTrackingPosition,
} from "@modules/devices/store/tracking-states.selector";
import { getSelectedDevice } from "@modules/devices/store/devices.selector";
import { DevicesState } from "@modules/devices/store/device.state";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
const $ = require("jquery");

@Component({
  selector: "app-table-state",
  templateUrl: "./table-state.component.html",
  styleUrls: ["../../containers/devices/devices.component.scss"],
})
export class TableStateComponent implements OnInit {
  @Input() deviceId!: any;
  @Input() isSmall: Boolean = false;
  @Input() pageSizeOptions: number = 15;

  selectedDriverId$ = this.store.pipe(select(getSelectedDevice));

  trackingStateInfo$ = this.store.pipe(select(getTrackingPosition));
  trackingStateInfo: any;
  topState: any;

  displayedColumns = ["value", "attribute"];
  _dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private store: Store<DevicesState>) {}

  ngOnInit(): void {
    this.selectedDriverId$.subscribe((db: any) => {
      this.loadState(db);
    });

    $(document).ready(() => {
      this.topState =
        (document.getElementById("tablist") as HTMLElement)?.clientHeight + 60;
    });
  }

  loadState(deviceId: any) {
    this.trackingStateInfo$.subscribe(
      (data) => {
        let temp = data.find((position: any) => position.deviceId == deviceId);

        this.trackingStateInfo = this.convertToColumn(temp);

        this._dataSource = new MatTableDataSource(this.trackingStateInfo);
        this._dataSource.paginator = this.paginator;
        this._dataSource.sort = this.sort;
      },
      (error) => {
        console.log(error.error);
      }
    );
  }

  convertToColumn(stateInfo: any) {
    let convertedMap = [];
    for (let attribute in stateInfo) {
      const value = stateInfo[attribute];
      convertedMap.push({
        attribute: capitalizeString(attribute),
        value,
      });
    }
    return convertedMap;
  }

  ngAfterViewInit() {
    const paginatorIntl = this.paginator?._intl;
    paginatorIntl.nextPageLabel = "";
    paginatorIntl.previousPageLabel = "";
    paginatorIntl.itemsPerPageLabel = "";
    paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 of ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} (${length})`;
    };

    // hide items Per Page number
    $(".mat-paginator-page-size").hide();

    this._dataSource = new MatTableDataSource(this.trackingStateInfo);

    this._dataSource.paginator = this.paginator;
    this._dataSource.sort = this.sort;
  }
}
