import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(
    req: HttpRequest<undefined>,
    next: HttpHandler
  ): Observable<HttpEvent<undefined>> {
    // return next.handle(req).pipe(
    //   catchError((error: any) => {
    //     if (error instanceof HttpErrorResponse) {
    //       if (
    //         error.status === 401 ||
    //         (error.url.indexOf('/api/session') !== -1 && error.status === 404)
    //       ) {
    //         this.router.navigateByUrl('/login');
    //       }
    //       return throwError(error);
    //     }
    //   })
    // );

    return next.handle(req).pipe(
      catchError(err => {
        if (err !== undefined && err instanceof HttpErrorResponse) {
          if (
            err.status === 401 
            // (err && err?.url.indexOf('/api/session') !== -1 && err.status === 404
          ) {
            this.router.navigateByUrl('/login');
          }
        }

        if (err.status === 401) {
          // remove Bearer token and redirect to login page
          this.router.navigateByUrl('/login');
        }
        return throwError(err);
      }));
  }
}
