import { SideNavItems, SideNavSection } from "@modules/navigation/models";

export const sideNavSections: SideNavSection[] = [
  {
    text: "MENU",
    items: [
      "dashboard",
      "Services",
      "DriverActual",
      "DriverSchedules",
      "Organization",
      "Master",
      "System",
      "camera",
      "chat",
    ],
  },
  {
    text: "MENU",
    items: ["dashboard", "DriverActual", "DriverSchedules", "Master"],
  },
];

export const sideNavItems: SideNavItems = {
  Services: {
    icon: "wrench",
    text: "Services",
    expanded: false,
    submenu: [
      {
        text: "Courtesy Bus Calendar",
        action: false,
        expanded: false,
        link: "/services/courtesy-bus-calendar",
        default: "/services/courtesy-bus-calendar",
      },
    ],
  },
  Master: {
    icon: "cog",
    text: "Master",
    expanded: false,
    submenu: [
      {
        icon: "screwdriver",
        text: "Drivers",
        action: false,
        expanded: false,
        link: "/drivers",
        default: "/drivers",
      },
      {
        icon: "user",
        text: "Users",
        action: false,
        expanded: false,
        link: "/users",
        default: "/users",
      },
      {
        icon: "clock",
        text: "Timetable",
        action: false,
        expanded: false,
        link: "/timetable",
        default: "/timetable",
      },
      {
        icon: "route",
        text: "Routes",
        action: false,
        expanded: false,
        link: "/routes",
        default: "/routes",
      },
      {
        icon: "sign",
        text: "Stations",
        action: false,
        expanded: false,
        link: "/stations",
        default: "/stations",
      },
      {
        icon: "taxi",
        text: "Vehicles",
        action: false,
        expanded: false,
        link: "/vehicles",
        default: "/vehicles",
      },
      {
        icon: "microchip",
        text: "Devices",
        action: false,
        expanded: false,
        link: "/devices",
        default: "/devices",
      },
    ],
  },
  dashboard: {
    icon: "tachometer-alt",
    text: "Dashboard",
    action: false,
    expanded: false,
    link: "/dashboard",
    default: "/dashboard",
  },
  DriverActual: {
    icon: "",
    text: "Driver Actual",
    expanded: false,
    submenu: [
      {
        icon: "calendar-check",
        text: "Actuals",
        action: false,
        expanded: false,
        link: "/actuals",
        default: "/actuals",
      }
    ],
  },
  Organization: {
    icon: "sitemap",
    text: "Organization",
    expanded: false,
    submenu: [
      {
        text: "Company",
        expanded: false,
        submenu: [
          {
            text: "System Company",
            action: false,
            expanded: false,
            link: "/organization/company/system-company",
            default: "/organization/company/system-company",
          },
          {
            text: "Client Company",
            action: false,
            expanded: false,
            link: "/organization/company/client-company",
            default: "/organization/company/client-company",
          },
        ],
      },
      {
        text: "Departments",
        action: false,
        expanded: false,
        link: "/organization/departments",
        default: "/organization/departments",
      },
      {
        text: "Contacts",
        action: false,
        expanded: false,
        link: "/organization/contacts",
        default: "/organization/contacts"
      },
      {
        text: "Employees",
        action: false,
        expanded: false,
        link: "/organization/employees",
        default: "/organization/employees",
      },
    ],
  },
  System: {
    icon: "",
    text: "System",
    expanded: false,
    submenu: [
      {
        text: "Configure Param",
        action: false,
        expanded: false,
        link: "/system/configure-param",
        default: "/system/configure-param",
      },
    ],
  },
  DriverSchedules: {
    icon: "calendar-alt",
    text: "Driver Schedules",
    action: false,
    expanded: false,
    link: "/schedules",
    default: "/schedules",
  },
  camera: {
    icon: "camera",
    text: "Camera",
    action: false,
    expanded: false,
    link: "/camera",
    default: "/camera",
  },
  chat: {
    icon: "comment",
    text: "Chat",
    action: false,
    expanded: false,
    link: "/chat",
    default: "/chat",
  },
};

export const sideNavItems20: SideNavItems = {
  dashboard: {
    icon: "tachometer-alt",
    text: "Dashboard",
    action: false,
    expanded: false,
    link: "/dashboard",
    default: "/dashboard",
  },
  DriverActual: {
    icon: "",
    text: "Driver Actual",
    expanded: false,
    submenu: [
      {
        icon: "calendar-check",
        text: "Actuals",
        action: false,
        expanded: false,
        link: "/actuals",
        default: "/actuals",
      }
    ],
  },
  DriverSchedules: {
    icon: "calendar-alt",
    text: "Driver Schedules",
    action: false,
    expanded: false,
    link: "/schedules",
    default: "/schedules",
  },
  Master: {
    icon: "cog",
    text: "Master",
    expanded: false,
    submenu: [
      {
        icon: "screwdriver",
        text: "Drivers", //
        action: false,
        expanded: false,
        link: "/drivers",
        default: "/drivers",
      },
      {
        icon: "clock", //
        text: "Timetable",
        action: false,
        expanded: false,
        link: "/timetable",
        default: "/timetable",
      },
      {
        icon: "route", //
        text: "Routes",
        action: false,
        expanded: false,
        link: "/routes",
        default: "/routes",
      },
      {
        icon: "sign",
        text: "Stations", //
        action: false,
        expanded: false,
        link: "/stations",
        default: "/stations",
      },
      {
        icon: "taxi",
        text: "Vehicles", //
        action: false,
        expanded: false,
        link: "/vehicles",
        default: "/vehicles",
      },
      {
        icon: "microchip", //
        text: "Devices",
        action: false,
        expanded: false,
        link: "/devices",
        default: "/devices",
      },
    ],
  },
};
