import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import config from "@common/shared/config/config";
import { removeAllCharacterAtStartOfString } from "@common/shared/utils/func.utils";
import { AuthService } from "@modules/auth/services";
import { CompanyService } from "@modules/organization/services";
import {
  LoadAllCompanies,
  SelectedCompany,
} from "@modules/organization/store/company/company.actions";
import {
  getSelectedCompany,
  selectAllCompanies,
} from "@modules/organization/store/company/company.selector";
import { CompaniesState } from "@modules/organization/store/company/company.state";
import { select, Store } from "@ngrx/store";
import { Subscription } from "rxjs";

@Component({
  selector: "app-dropdown-company",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./dropdown-company.component.html",
  styleUrls: ["../../../../app/app.component.scss"],
})
export class DropdownCompanyComponent implements OnInit {
  //output master_Companies
  @Output() MasterCompanies = new EventEmitter<any>();
  @Input() selected_company_id: any;

  subscription: Subscription = new Subscription();
  isGuest = !this.authService.isAuthenticated();

  isView: boolean = true;
  master_Companies!: any;
  master_Companies$ = this.storeCompany.pipe(select(selectAllCompanies));

  isDisabled: boolean =
    this.authService.getUserInfo().company_id != -1 ? true : false;

  objAll: any = {
    id: -1,
    name: "All",
  };

  selectedCompany$ = this.storeCompany.pipe(select(getSelectedCompany));
  selected_company: any;

  getUrl = removeAllCharacterAtStartOfString(window.location.pathname, "/");
  website: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private storeCompany: Store<CompaniesState>,
    public companyService: CompanyService,
    public authService: AuthService,
    private actRoute: ActivatedRoute
  ) {}

  selectedCompany() {
     this.selectedCompany$.subscribe((data: any) => {
      if(this.master_Companies && this.master_Companies.length > 0){
        let num =this.authService.getUserInfo().company_id;
        if (data) {
          if(num && num != -1 && data?.id != num){
            this.selected_company = this.master_Companies.find(
              (x: any) => x.id == num
            ) ?? this.objAll;
            this.selected_company_id = this.selected_company.id;

          }
          else{
            this.selected_company = data;
            this.selected_company_id = this.selected_company.id;
          }
        } else {
          if (this.website) {
            this.selected_company =
              this.master_Companies.find((x: any) => x.website == `${config.FRONTEND_URL}${this.website}`) ??
              this.objAll;
          } else {
            if (!this.isGuest) {
              // this.selected_company =
              //   this.master_Companies.find(
              //     (x: any) => x.website == this.getUrl
              //   ) ?? this.objAll;

              this.selected_company =
              this.master_Companies.find(
                (x: any) => x.id == this.authService.getUserInfo().company_id
              ) ?? this.objAll;
            }
          }
        }
  
        this.storeCompany.dispatch(
          SelectedCompany({
            id: this.selected_company.id,
            selectedCompany: this.selected_company ?? this.objAll,
          })
        );
  
        this.selected_company_id = this.selected_company.id;
        this.isView = false;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnInit() {

    this.website = this.actRoute.snapshot.params["website"];
    this.storeCompany.dispatch(LoadAllCompanies());

    // this.loadAllCompanies();
    this.subscription.add(
      this.master_Companies$.subscribe((data: any) => {
        if (data) {
          this.master_Companies = data;
          this.MasterCompanies.emit(this.master_Companies);
          this.selectedCompany();
        } else {
          // this.MasterCompanies.emit(this.master_Companies);
          // this.selectedCompany();
        }
      })
    );
  }

  loadAllCompanies() {
    this.master_Companies$.subscribe((data) => {
      if (data && data.length > 0) {
        this.master_Companies = data;
        this.isView = false;
        this.MasterCompanies.emit(this.master_Companies);
      }
    });
  }

  selectionChangeCompany(event: any) {
    this.authService.updateUserInfo(event.value);
    let company = this.master_Companies.find((x: any) => x.id == event.value);
    // this.selected_company_id = this.selected_company.id;
    this.storeCompany.dispatch(
      SelectedCompany({
        id: event.value,
        selectedCompany: company ?? this.objAll,
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
