import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Department } from "../../models/department";
import config from "@common/shared/config/config";
import { AuthService } from "@modules/auth/services";

@Injectable({
  providedIn: "root", 
})
export class ApiService {
  private BACKEND_URL = config.BACKEND_URL;
  private DEPARTMENT_URL = `${this.BACKEND_URL}/hr-department`;
  private MODEL_TO_URL_MAP: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.MODEL_TO_URL_MAP = {
      [`${Department.name}`]: `${this.DEPARTMENT_URL}`,
    };
  }

  //#region DEPARTMENT
  public getAllDepartment(): Observable<Department[]> {
    return new Observable<Department[]>((subscriber) => {
      this.http.get(`${this.DEPARTMENT_URL}`).subscribe(
        (departments: any) => {
          subscriber.next(departments as Department[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param Department
   */
  public addDepartment(Department: Department): Observable<any> {
    return new Observable<Department>((subscriber) => {
      this.http.post(`${this.DEPARTMENT_URL}`, Department).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param Department
   */
  public updateDepartment(Department: Department): Observable<any> {
    return new Observable<Department>((subscriber) => {
      this.http.put(`${this.DEPARTMENT_URL}/${this.authService.getUserInfo().id}`, Department).subscribe(
        (newInfo: any) => {
          subscriber.next(newInfo);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
  //#endregion

  public deleteEntity(id: number, className: string): Observable<any> {
    let url: string = this.MODEL_TO_URL_MAP[className];

    if (!url || url.length === 0) return {} as Observable<any>;

    return new Observable<any>((subscriber) => {
      this.http.delete(`${url}/${id}`).subscribe(
        () => {
          subscriber.next("ok");
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
