import { Injectable } from "@angular/core";
import { ApiService } from "./api-users.service";
import { Observable } from "rxjs";
import { User } from "../models/user";
import { ResUser } from "../models/ResUser";
import { Partner } from "@modules/organization/models/Partner";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private apiService: ApiService) {}

  public loadAllRoles(): Observable<User[]> {
    return this.apiService.getAllRoles();
  }

  public loadAllUsers(): Observable<User[]> {
    return this.apiService.getAllUsers();
  }

  public addUser(user: User): Observable<User> {
    return this.apiService.addUser(user);
  }

  public updateUser(user: User): Observable<User> {
    return this.apiService.updateUser(user);
  }

  // getConfirmPassword
  public getConfirmPassword(token: string): Observable<any> {
    return this.apiService.getConfirmPassword(token);
  }

  //updatePassword
  public updatePassword(user: User): Observable<User> {
    return this.apiService.updatePassword(user);
  }

  public deleteUser(id: number): Observable<any> {
    return this.apiService.deleteEntity(id, User.name);
  }

  public getAllUserInfo(): User[] {
    const userInfoStr = localStorage.getItem("user");
    if (!userInfoStr || userInfoStr.length === 0) return {} as User[];
    const userInfoJson = JSON.parse(userInfoStr);
    const user: any = new User(userInfoJson);

    return user;
  }

  public addResUser(resUser: ResUser): Observable<ResUser> {
    return this.apiService.addResUser(resUser);
  }

  public updateResUser(resUser: ResUser): Observable<ResUser> {
    return this.apiService.updateResUser(resUser);
  }

  public deleteResUser(id: number): Observable<any> {
    return this.apiService.deleteEntity(id, "res-user");
  }
}
