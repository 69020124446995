export class Department {
  id: number;
  name: string;
  company_id: number;
  company_name: string;
  manager_id: number;
  manager_name: string;
  parent_id: number;
  parent_name: string;

  constructor(info: any = {}) {
    const { id
        , name
        , company_id
        , company_name
        , manager_id
        , manager_name
        , parent_id
        , parent_name
     } 
        = info;
    this.id = id;
    this.name = name;
    this.company_id = company_id;
    this.company_name = company_name;
    this.manager_id = manager_id;
    this.manager_name = manager_name;
    this.parent_id = parent_id;
    this.parent_name = parent_name;
  }
}
