export class Contact {
    id: number;
    company_id : number;
    company_name : string;
    name: string;
    mobile: string;
    email: string;
    city: string;
    country: string;

  
    constructor(info: any = {}) {
      const { id,
        company_id,
        company_name,
        name,
        mobile,
        email,
        city,
        country
       } 
          = info;
      this.id = id;
      this.company_id = company_id;
      this.company_name = company_name;
      this.name = name;
      this.mobile = mobile;
      this.email = email;
      this.city = city;
      this.country = country;
    }
  }
  