import { Component, OnInit } from '@angular/core';
import { LocalSettingsService } from "@common/services/LocalSettings.service";
import { langs, languages } from '@common/shared/utils/variable.utils';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['../../../../app/app.component.scss']
})
export class ChangeLanguageComponent implements OnInit {
  allLanguagesName = langs;
  allLanguages = languages;
  Language: string = this.translate.currentLang || 'jp';
  
  constructor(
    private _localSettings: LocalSettingsService,
    public translate: TranslateService,
  ) { }

  changeLanguage(lang:string= 'jp') {
    this.Language = lang;
    this.translate.use(lang);
    this._localSettings.setLanguage(lang);
  }

  ngOnInit(): void {
    
  }

}
