import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '@common/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-image',
  templateUrl: './table-image.component.html',
  // styleUrls: ["../../containers/vehicles/vehicles.component.scss"]
})
export class TableImageComponent implements OnInit {
  @Input() listImages: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    public toastService: ToastService,
    private translateService: TranslateService
  ) {
  }
  
  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  imageChange(image:any){
    this.activeModal.close(image);
  }
}
