import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Injectable()
export class CanActivateLoginRoute implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    if (this.authService.isAuthenticated() === false) {
      return true;
    }
    this.router.navigateByUrl('/');
    return false;
  }
}
