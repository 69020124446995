import { ModalAddDevicesComponent } from "./modal-add-devices/modal-add-devices.component";
import { ModalDeleteDevicesComponent } from "./modal-delete-devices/modal-delete-devices.component";
import { ModalUpdateDevicesComponent } from "./modal-update-devices/modal-update-devices.component";
import { SortIconComponent } from "./sort-icon/sort-icon.component";
import { TableDevicesComponent } from "./table-devices/table-devices.component";
import { TableStateComponent } from "./table-state/table-state.component";
import { TimeTableComponent } from "./time-table/time-table.component";
import { WebsocketComponent } from "./websocket/websocket.component";


export const components = [
    SortIconComponent,
    
    ModalAddDevicesComponent,
    ModalUpdateDevicesComponent,
    ModalDeleteDevicesComponent,
    WebsocketComponent,
    TableStateComponent,
    TimeTableComponent,
    TableDevicesComponent,
];

export * from "./modal-add-devices/modal-add-devices.component"
export * from "./modal-update-devices/modal-update-devices.component"
export * from "./modal-delete-devices/modal-delete-devices.component"
export * from "./sort-icon/sort-icon.component"
export * from "./websocket/websocket.component"
export * from "./table-state/table-state.component"
export * from "./time-table/time-table.component"
export * from "./table-devices/table-devices.component"