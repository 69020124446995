export class ResUser {
    id: number;
    active: boolean;
    login: string;
    password: string;
    company_id: number;
    partner_id: number;
    constructor(info: any = {}) {
      const {
        id,
        active,
        login,
        password,
        company_id,
        partner_id
      } = info;
      this.id = id;
      this.active = active;
      this.login = login;
      this.password = password;
      this.company_id = company_id;
      this.partner_id = partner_id;
    }
  }
  