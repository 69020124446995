import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigurationInterceptor } from './configuration-interceptor';
import { AuthenticationInterceptor } from './authentication-interceptor';

export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ConfigurationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
];
