import { ResUser } from '@modules/users/models/ResUser';
import { User } from '@modules/users/models/user';
import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
    LOAD_ALL_ROLES = '[Roles] Load All Roles',
    LOAD_ALL_ROLES_SUCCESS = '[Roles] Load All Roles Success',
    LOAD_ALL_ROLES_FAILURE = '[Roles] Load All Roles Failure',

    LOAD_ALL_USERS = '[Users] Load All Users',
    LOAD_ALL_USERS_SUCCESS = '[Users] Load All Users Success',
    LOAD_ALL_USERS_FAILURE = '[Users] Load All Users Failure',

    ADD_USER = '[Users] Add User',
    ADD_USER_SUCCESS = '[Users] Add User Success',
    ADD_USER_FAILURE = '[Users] Add User Failure',

    ADD_ROLE = '[Roles] Add Roles',
    ADD_ROLE_SUCCESS = '[Roles] Add Roles Success',
    ADD_ROLE_FAILURE = '[Roles] Add Roles Failure',

    UPDATE_USER = '[Users] Update User',
    UPDATE_USER_SUCCESS = '[Users] Update User Success',
    UPDATE_USER_FAILURE = '[Users] Update User Failure',
    DELETE_USER = '[Users] Delete User',
    DELETE_USER_SUCCESS = '[Users] Delete User Success',
    DELETE_USER_FAILURE = '[Users] Delete User Failure',

    UPDATE_ROLE = '[Roles] Update Role',
    UPDATE_ROLE_SUCCESS = '[Roles] Update Role Success',
    UPDATE_ROLE_FAILURE = '[Roles] Update Role Failure',

    ADD_RES_USER = '[ResUsers] Add ResUser',
    ADD_RES_USER_SUCCESS = '[ResUsers] Add ResUser Success',
    ADD_RES_USER_FAILURE = '[ResUsers] Add ResUser Failure',
    UPDATE_RES_USER = '[ResUsers] Update ResUser',
    UPDATE_RES_USER_SUCCESS = '[ResUsers] Update ResUser Success',
    UPDATE_RES_USER_FAILURE = '[ResUsers] Update ResUser Failure',
    DELETE_RES_USER = '[ResUsers] Delete ResUsers',
    DELETE_RES_USER_SUCCESS = '[ResUsers] Delete ResUsers Success',
    DELETE_RES_USER_FAILURE = '[ResUsers] Delete ResUsers Failure',

    // getConfirmPassword
    GET_CONFIRM_PASSWORD = '[Users] Get Confirm Password',
    GET_CONFIRM_PASSWORD_SUCCESS = '[Users] Get Confirm Password Success',
    GET_CONFIRM_PASSWORD_FAILURE = '[Users] Get Confirm Password Failure',

    UPDATE_PASSWORD = '[Users] Update Password',
    UPDATE_PASSWORD_SUCCESS = '[Users] Update Password Success',
    UPDATE_PASSWORD_FAILURE = '[Users] Update Password Failure',

    RESET_ERROR = '[Users] Reset Error',
    RESET_USER = '[Users] Reset User',
}

//==============================================
export const LoadAllRoles = createAction(UserActionTypes.LOAD_ALL_ROLES);

export const LoadAllRolesSuccess = createAction(
    UserActionTypes.LOAD_ALL_ROLES_SUCCESS,
    props<{ roles: any[] }>()
);

export const LoadAllRolesFailure = createAction(
    UserActionTypes.LOAD_ALL_ROLES_FAILURE,
    props<{ error: string }>()
);

//==============================================
export const LoadAllUsers = createAction(UserActionTypes.LOAD_ALL_USERS);

export const LoadAllUsersSuccess = createAction(
    UserActionTypes.LOAD_ALL_USERS_SUCCESS,
    props<{ users: User[] }>()
);

export const LoadAllUsersFailure = createAction(
    UserActionTypes.LOAD_ALL_USERS_FAILURE,
    props<{ error: string }>()
);
//==============================================
export const AddUser = createAction(
    UserActionTypes.ADD_USER,
    props<{ user: any }>()
);

export const AddUserSuccess = createAction(
    UserActionTypes.ADD_USER_SUCCESS,
    props<{ user: User }>()
);

export const AddUserFailure = createAction(
    UserActionTypes.ADD_USER_FAILURE,
    props<{ error: any }>()
);

//==============================================
export const AddResUser = createAction(
    UserActionTypes.ADD_RES_USER,
    props<{ resUser: any }>()
);

export const AddResUserSuccess = createAction(
    UserActionTypes.ADD_RES_USER_SUCCESS,
    props<{ resUser: any }>()
);

export const AddResUserFailure = createAction(
    UserActionTypes.ADD_RES_USER_FAILURE,
    props<{ error: string }>()
);
//==============================================
export const UpdateResUser = createAction(
    UserActionTypes.UPDATE_RES_USER,
    props<{ resUser: any }>()
);

export const UpdateResUserSuccess = createAction(
    UserActionTypes.UPDATE_RES_USER_SUCCESS,
    props<{ resUser: any }>()
);

export const UpdateResUserFailure = createAction(
    UserActionTypes.UPDATE_RES_USER_FAILURE,
    props<{ error: string }>()
);

export const DeleteResUser = createAction(
    UserActionTypes.DELETE_RES_USER,
    props<{ userId: number }>()
);

export const DeleteResUserSuccess = createAction(
    UserActionTypes.DELETE_RES_USER_SUCCESS,
    props<{ userId: number }>()
);

export const DeleteResUserFailure = createAction(
    UserActionTypes.DELETE_RES_USER_FAILURE,
    props<{ error: string }>()
);

//==============================================
export const UpdateUser = createAction(
    UserActionTypes.UPDATE_USER,
    props<{ user: any }>()
);

export const UpdateUserSuccess = createAction(
    UserActionTypes.UPDATE_USER_SUCCESS,
    props<{ user: User }>()
);

export const UpdateUserFailure = createAction(
    UserActionTypes.UPDATE_USER_FAILURE,
    props<{ error: string }>()
);

export const DeleteUser = createAction(
    UserActionTypes.DELETE_USER,
    props<{ userId: number }>()
);

export const DeleteUserSuccess = createAction(
    UserActionTypes.DELETE_USER_SUCCESS,
    props<{ userId: number }>()
);

export const DeleteUserFailure = createAction(
    UserActionTypes.DELETE_USER_FAILURE,
    props<{ error: string }>()
);

//getConfirmPassword
export const GetConfirmPassword = createAction(
    UserActionTypes.GET_CONFIRM_PASSWORD,
    props<{ token: string }>()
);

export const GetConfirmPasswordSuccess = createAction(
    UserActionTypes.GET_CONFIRM_PASSWORD_SUCCESS,
    props<{ response: any }>()
);

export const GetConfirmPasswordFailure = createAction(
    UserActionTypes.GET_CONFIRM_PASSWORD_FAILURE,
    props<{ error: string }>()
);

//updatePassword
export const UpdatePassword = createAction(
    UserActionTypes.UPDATE_PASSWORD,
    props<{ user: any }>()
);

export const UpdatePasswordSuccess = createAction(
    UserActionTypes.UPDATE_PASSWORD_SUCCESS,
    props<{ response: any }>()
);

export const UpdatePasswordFailure = createAction(
    UserActionTypes.UPDATE_PASSWORD_FAILURE,
    props<{ error: string }>()
);

export const ResetError = createAction(
    UserActionTypes.RESET_ERROR
);

export const ResetUser = createAction(
    UserActionTypes.RESET_USER
);
