import { createAction, props } from '@ngrx/store';
import { Device } from '../models/device';

export enum DeviceActionTypes {
  LOAD_ALL_DEVICES = '[Devices] Load All Devices',
  LOAD_ALL_DEVICES_SUCCESS = '[Devices] Load All Success',
  LOAD_ALL_DEVICES_FAILURE = '[Devices] Load All Failure',
  ADD_DEVICE = '[Devices] Add Device',
  ADD_DEVICE_SUCCESS = '[Devices] Add Device Success',
  ADD_DEVICE_FAILURE = '[Devices] Add Device Failure',
  UPDATE_DEVICE = '[Devices] Update Device',
  UPDATE_DEVICE_SUCCESS = '[Devices] Update Device Success',
  UPDATE_DEVICE_FAILURE = '[Devices] Update Device Failure',
  REMOVE_DEVICE = '[Devices] Remove Device',
  REMOVE_DEVICE_SUCCESS = '[Devices] Remove Device Success',
  REMOVE_DEVICE_FAILURE = '[Devices] Remove Device Failure',
  UPDATE_DEVICES_FROM_SOCKET = '[Devices] Update Devices From Socket',
  SELECT_DEVICE = '[Devices] Select Device',
  CLEAR_SELECTED_DEVICE = '[Devices] Clear Selected Device',
  ADD_DEVICE_TO_DISPLAY_LIST = '[Devices] Add Device To Display List',
  REMOVE_DEVICE_FROM_DISPLAY_LIST = '[Devices] Remove Device From Display List',
  TOGGLE_DEVICE_NAME = '[Devices] Toggle Device Name',
  RESET_ERROR = '[Devices] Reset Error',
}

export const ToggleDeviceName = createAction(DeviceActionTypes.TOGGLE_DEVICE_NAME,
  props<{ isShow: boolean }>()
  );

export const LoadAllDevices = createAction(DeviceActionTypes.LOAD_ALL_DEVICES);

export const LoadAllDevicesSuccess = createAction(
  DeviceActionTypes.LOAD_ALL_DEVICES_SUCCESS,
  props<{ devices: Device[] }>()
);

export const LoadAllDevicesFailure = createAction(
  DeviceActionTypes.LOAD_ALL_DEVICES_FAILURE,
  props<{ error: string }>()
);

export const AddDevice = createAction(
  DeviceActionTypes.ADD_DEVICE,
  props<{ device: Device }>()
);

export const AddDeviceSuccess = createAction(
  DeviceActionTypes.ADD_DEVICE_SUCCESS,
  props<{ device: Device }>()
);

export const AddDeviceFailure = createAction(
  DeviceActionTypes.ADD_DEVICE_FAILURE,
  props<{ error: any }>()
);

export const UpdateDevice = createAction(
  DeviceActionTypes.UPDATE_DEVICE,
  props<{ device: Device }>()
);

export const UpdateDeviceSuccess = createAction(
  DeviceActionTypes.UPDATE_DEVICE_SUCCESS,
  props<{ device: Device }>()
);

export const UpdateDeviceFailure = createAction(
  DeviceActionTypes.UPDATE_DEVICE_FAILURE,
  props<{ error: any }>()
);

export const UpdateDevicesFromSocket = createAction(
  DeviceActionTypes.UPDATE_DEVICES_FROM_SOCKET,
  props<{ devices: Device[] }>()
);

export const RemoveDevice = createAction(
  DeviceActionTypes.REMOVE_DEVICE,
  props<{ deviceId: number }>()
);

export const RemoveDeviceSuccess = createAction(
  DeviceActionTypes.REMOVE_DEVICE_SUCCESS,
  props<{ deviceId: number }>()
);

export const RemoveDeviceFailure = createAction(
  DeviceActionTypes.REMOVE_DEVICE_FAILURE,
  props<{ error: string }>()
);

export const SelectDevice = createAction(
  DeviceActionTypes.SELECT_DEVICE,
  props<{ deviceId: number }>()
);

export const ClearSelectedDevice = createAction(
  DeviceActionTypes.CLEAR_SELECTED_DEVICE
);

export const ResetError = createAction(
  DeviceActionTypes.RESET_ERROR
);
