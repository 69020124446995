import { TrackingState } from '@modules/devices/models/tracking-state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrackingStates } from './tracking-state.reducers';

export const trackingStatesKey = 'tracking';
export const selectTrackingStates = createFeatureSelector<TrackingStates>(
  trackingStatesKey
);

export const getTrackingStateById = createSelector(
  selectTrackingStates,
  (state:any, deviceId: number) =>
    state.positions.find(
      (position: TrackingState) => position.deviceId === deviceId
    )
);

export const getDisplayedPositionsList = createSelector(
  selectTrackingStates,
  (state: any) => state.tracking.displayedPositions
);

export const getInfoMoveList = createSelector(
  selectTrackingStates,
  (state: any) => state.tracking.displayedInfoMove
);

export const getTrackingPosition = createSelector(
  selectTrackingStates,
  (state:any) => state.tracking.positions
)
