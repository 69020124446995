export class User {
  cd: string = "";
  id!: number;
  sys_user_id!: number;
  name?: String;
  email?: String;
  timezone: string = "Asia/Tokyo";
  role_id?: number;
  role_name?: string;
  company_id?: number;
  company_name?: string;
  partner_id?: number;
  res_user_id?: number;

  constructor(info: any = {}) {
    const {
        cd,
        id,
        sys_user_id,
        name,
        email,
        timezone,
        role_id,
        role_name,
        company_id,
        company_name,
        partner_id,
        res_user_id
      } = info;

    if (Object.keys(info).length === 0) return;


    this.cd = cd;
    this.id = id;
    this.sys_user_id = sys_user_id;
    this.name = name;
    this.email = email;
    this.timezone = timezone;
    this.role_id = role_id;
    this.role_name = role_name;
    this.company_id = company_id;
    this.company_name = company_name;
    this.partner_id = partner_id;
    this.res_user_id = res_user_id;
  }
}
