import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import config from '../../app-common/shared/config/config';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private BACKEND_WS_URL = config.BACKEND_WS_URL;
  private socket!: WebSocket;

  constructor() {}

  public initSocket(): void {
    this.socket = new WebSocket(this.BACKEND_WS_URL);
  }

  public send(message: any): void {
    this.socket.send(message);
  }

  public onMessage(): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.onmessage = (event) => {
        observer.next(event);
      };
    });
  }

  public onOpen(): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.onopen = (event) => {
        observer.next(event);
      };
    });
  }

  public onClose(): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.onclose = (event) => {
        observer.next(event);
      };
    });
  }
}
