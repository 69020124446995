import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState } from '../reducers/user.reducers';

export const usersKey = 'users';
export const selectUsers = createFeatureSelector<UsersState>(usersKey);

export const selectAllUsers = createSelector(
  selectUsers,
  (state: any) => state.users
);

export const selectAllRoles = createSelector(
  selectUsers,
  (state: any) => state.users.roles
);

//infoConfirmPassword
export const selectInfoConfirmPassword = createSelector(
  selectUsers,
  (state: any) => state?.users.infoConfirmPassword
);

//select user update password
export const selectUserUpdatePassword = createSelector(
  selectUsers,
  (state: any) => state?.users.user
);

//responseErrorMessage
export const responseErrorMessage = createSelector(
  selectUsers,
  (state: any) => state?.users.errorMessage
);