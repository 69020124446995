
import { createReducer, on } from '@ngrx/store';
import { updateEventFromSocket } from './event.actions';

export interface EventsState {
  events: Event[];
}

const initialState: EventsState = {
  events: [],
};

const _eventReducer = createReducer(
  initialState,
  on(updateEventFromSocket, (state, { events }) => ({
    ...state,
    events,
  }))
);

export function reducer(state: EventsState | undefined, action: any) {
  return _eventReducer(state, action);
}
