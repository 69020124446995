import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SystemUser } from "../models/system-user";
import { User } from "../../app-common/models/user";
import config from "../../app-common/shared/config/config";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private BACKEND_URL = config.BACKEND_URL;
  private DEVICE_URL = `${this.BACKEND_URL}/devices`;
  private GEOFENCES_URL = `${this.BACKEND_URL}/geofences`;
  private DRIVER_URL = `${this.BACKEND_URL}/app-drivers`;
  private SYSTEM_USER_URL = `${this.BACKEND_URL}/users`;
  private ROUTE_URL = `${this.BACKEND_URL}/app-routes`;
  private ROUTE_DETAIL_URL = `${this.BACKEND_URL}/app-route-details`;
  private STATION_URL = `${this.BACKEND_URL}/app-stations`;
  private VEHICLE_URL = `${this.BACKEND_URL}/app-vehicles`;
  private SCHEDULE_URL = `${this.BACKEND_URL}/app-schedules`;
  private ACTUAL_URL = `${this.BACKEND_URL}/app-actuals`;
  private USER_URL = `${this.BACKEND_URL}/app-users`;
  private MODEL_TO_URL_MAP: any;

  constructor(private http: HttpClient) {
    this.MODEL_TO_URL_MAP = {
      [`${SystemUser.name}`]: `${this.SYSTEM_USER_URL}`,
      [`${User.name}`]: `${this.USER_URL}`,
    };
  }

  // //#region USERS
  // /**
  //  *
  //  */
  // public getAllSystemUsers(): Observable<Array<SystemUser>> {
  //   return new Observable<SystemUser[]>((subscriber) => {
  //     this.http.get(`${this.SYSTEM_USER_URL}`).subscribe(
  //       (users : Array<SystemUser>) => {
  //         subscriber.next(users);
  //         subscriber.complete();
  //       },
  //       (error) => {
  //         subscriber.error(error);
  //       }
  //     );
  //   });
  // }

  /**
   *
   * @param name
   * @param email
   * @param password
   */
  public addSystemUser(
    timezone: string,
    cd: string,
    name: string,
    email: string,
    password: string,
    company_id: number
  ): Observable<SystemUser> {
    return new Observable<SystemUser>((subscriber) => {
      this.http
        .post(this.SYSTEM_USER_URL, {
          timezone,
          cd,
          name,
          email,
          password,
          deviceLimit: -1,
          company_id: company_id,
        })
        .subscribe(
          (userInfo) => {
            subscriber.next(userInfo as SystemUser);
            subscriber.complete();
          },
          (error) => {
            subscriber.error(error);
          }
        );
    });
  }

  /**
   *
   * @param user
   */
  public updateSystemUser(user: SystemUser): Observable<SystemUser> {
    return new Observable<SystemUser>((subscriber) => {
      this.http.put(`${this.SYSTEM_USER_URL}/${user.id}`, user).subscribe(
        (newInfo: any) => {
          subscriber.next(new SystemUser(newInfo));
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public deleteEntity(id: number, className: string): Observable<any> | null {
    let url: string = this.MODEL_TO_URL_MAP[className];

    if (!url || url.length === 0) return null;

    return new Observable<any>((subscriber) => {
      this.http.delete(`${url}/${id}`).subscribe(
        () => {
          subscriber.next("ok");
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
