import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  AuthActionTypes,
  LogInSuccess,
  LogInFailure,
  LogOutSuccess,
  LogOutFailure,
} from '../actions/auth.actions';

import { map, catchError, mergeMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { SystemUser } from '../../models/system-user';
import { AuthService } from '@modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  LogIn = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActionTypes.LOGIN),
      mergeMap((data: any) => {
        return this.authService.logIn(data.email, data.password).pipe(
          map((userInfo) => {
            // const user = new SystemUser(userInfo);
            return LogInSuccess({ user:userInfo });
          }),
          // TODO: why use of() here?
          catchError((error) => of(LogInFailure({ error })))
        );
      })
    );
  });

  LogInSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(AuthActionTypes.LOGIN_SUCCESS),
        tap((payload: any) => {
          this.authService.saveUserInfo(payload.user);
          this.router.navigateByUrl('/');
        })
      );
    },
    // The option { dispatch: false }: not allow effect to dispatch an action (prevent infinite loop)
    // https://ngrx.io/guide/effects/lifecycle#non-dispatching-effects
    { dispatch: false }
  );

  LogInFailure = createEffect(
    () => {
      return this.actions.pipe(ofType(AuthActionTypes.LOGIN_FAILURE));
    },
    { dispatch: false }
  );

  LogOut = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActionTypes.LOGOUT),
      mergeMap(() => {
        return this.authService.logout().pipe(
          map(() => {
            return LogOutSuccess();
          }),
          catchError((error) => of(LogOutFailure({ error })))
        );
      })
    );
  });

  LogOutSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        tap(() => {
          this.authService.removeUserInfo();
          this.router.navigateByUrl('/login');
        })
      );
    },
    { dispatch: false }
  );

  LogOutFailure = createEffect(
    () => {
      return this.actions.pipe(ofType(AuthActionTypes.LOGOUT_FAILURE));
    },
    { dispatch: false }
  );
}
