import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LogOut } from "@modules/auth/store/actions/auth.actions";
import { AppState } from "@modules/auth/store/app.state";
import { AuthService } from "@modules/auth/services";
import { Store } from "@ngrx/store";
import { ModalService } from "@common/components/_modal";

@Component({
  selector: "app-top-nav-user",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./top-nav-user.component.html",
  styleUrls: ["../../../../app/app.component.scss"],
})
export class TopNavUserComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    public authService: AuthService,
    private modalService: ModalService
  ) {}

  ngOnInit() {}

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  acceptLogout(id: string) {
    this.store.dispatch(LogOut());
    this.closeModal(id);
  }
}
