import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  DeviceActionTypes,
  AddDeviceSuccess,
  AddDeviceFailure,
  LoadAllDevicesSuccess,
  LoadAllDevicesFailure,
  UpdateDeviceSuccess,
  UpdateDeviceFailure,
  RemoveDeviceSuccess,
  RemoveDeviceFailure,
  ClearSelectedDevice,
} from './device.actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { RemovePositionFromDisplayList } from './tracking-state.actions';
import { DeviceService } from '../services/device.service';
import { Device } from '../models/device';
import { ToastService } from '@common/services/toast.service';
import { TranslateService } from "@ngx-translate/core";


@Injectable({ providedIn: 'root' })
export class DeviceEffects {
  constructor(
    private actions: Actions,
    private deviceService: DeviceService,
    public toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  //#region Fetching Devices Effects
  LoadAllDevices = createEffect(() => {
    return this.actions.pipe(
      ofType(DeviceActionTypes.LOAD_ALL_DEVICES),
      mergeMap(() => {
        return this.deviceService.loadAllDevices().pipe(
          map((devices) => {
            return LoadAllDevicesSuccess({ devices });
          }),
          catchError((error) => {
            return of(LoadAllDevicesFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllDevicesFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DeviceActionTypes.LOAD_ALL_DEVICES_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Registering device effects
  AddDevice = createEffect(() => {
    return this.actions.pipe(
      ofType(DeviceActionTypes.ADD_DEVICE),
      mergeMap((args: any) => {
        const device: Device = args.device;
        return this.deviceService.addDevice(device).pipe(
          map((newDeviceInfo) => {
            const newDevice = new Device(newDeviceInfo);
            return AddDeviceSuccess({ device: newDevice });
          }),
          catchError((error) => {
            return of(AddDeviceFailure({ error }));
          })
        );
      })
    );
  });

  AddDeviceFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DeviceActionTypes.ADD_DEVICE_FAILURE),
        tap((args: any) => {
          // this.toastService.showError(args.error.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Updating device effects
  UpdateDevice = createEffect(() => {
    return this.actions.pipe(
      ofType(DeviceActionTypes.UPDATE_DEVICE),
      mergeMap((args: any) => {
        return this.deviceService.updateDevice(args.device).pipe(
          map((newDevice) => {
            return UpdateDeviceSuccess({ device: newDevice });
          }),
          catchError((response) => {
            return of(UpdateDeviceFailure({ error: response.error }));
          })
        );
      })
    );
  });

  UpdateDeviceFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DeviceActionTypes.UPDATE_DEVICE_FAILURE),
        tap((errorResponse: any) => {
          // this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Removing device effects
  RemoveDevice = createEffect(() => {
    return this.actions.pipe(
      ofType(DeviceActionTypes.REMOVE_DEVICE),
      mergeMap(({ deviceId }) => {
        return this.deviceService.deleteDevice(deviceId).pipe(
          map(() => {
            this.translateService.get('devices.delete_success').subscribe((res: string) => {
              this.toastService.showSuccess(res);
            });
            return RemoveDeviceSuccess({ deviceId });
          }),
          catchError((response) => {
            return of(RemoveDeviceFailure({ error: response.error }));
          })
        );
      })
    );
  });

  RemoveDeviceSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(DeviceActionTypes.REMOVE_DEVICE_SUCCESS),
      mergeMap(({ deviceId }) => {
        return [
          RemovePositionFromDisplayList({ deviceId }),
          ClearSelectedDevice(),
        ];
      })
    );
  });

  RemoveDeviceFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DeviceActionTypes.REMOVE_DEVICE_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion
}
