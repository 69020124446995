import * as devices from './device.reducers';
import * as tracking from './tracking-state.reducers';
import * as events from './event.reducers';


export interface DevicesState {
  devicesState: devices.DevicesState;
  trackingStates: tracking.TrackingStates;
  eventsState: events.EventsState;
}

export const reducers = {
  devices: devices.reducer,
  tracking: tracking.reducer,
  events: events.reducer,
};
