import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const _expand$ = new Subject<string[]>();

const expandedTable: {
    [index: string]: boolean;
} = {};

@Injectable()
export class SideNavService {
    constructor(private http: HttpClient) {}

    get expand$() {
        return _expand$;
    }

    isExpanded(hash: string): boolean {
        if (expandedTable[hash]) {
            return true;
        }
        return false;
    }

    setExpanded(hash: string, expanded: boolean) {
        expandedTable[hash] = expanded;
    }

    get sideNavData$(): Observable<any> {
        return this.http.get('/assets/sideNavData.json', { responseType: 'json' });
    }
}
