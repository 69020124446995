import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "@modules/auth/services";
import { Observable } from "rxjs";
import config from "../config/config";
import { isNullOrEmpty } from "../utils/func.utils";
import { routes } from "../../../../app/app-routing.module";
@Injectable()
export class CanActivateGuardedRoute implements CanActivate {
  private BACKEND_URL = config.BACKEND_URL;
  master_Companies: Record<string, any>[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    let website: any = _route.paramMap.get("website");
    const arrUrl: Array<any> = _state.url.split("/");
    let page:any = null;
    let isExistOnRoutes = false;

    // if (arrUrl.length > 2) {
    //   website = arrUrl[1];
    //   page = arrUrl.slice(2).join("/");
    //   if(routes.find((item) => item.path === page) === undefined)
    //   {
    //     this.router.navigateByUrl("/" + website);
    //     return false;
    //   }
    // }

    isExistOnRoutes =
      routes.find((item) => item.path === website) !== undefined;

    if (this.authService.isAuthenticated()) {
      if (isExistOnRoutes || isNullOrEmpty(website)) {
        return true;
      }
    } else {
      if (
        website === "login" ||
        website === "register" ||
        website === "forgot-password" ||
        website === "confirm-password"
      ) {
        return true;
      }

      if (isExistOnRoutes || isNullOrEmpty(website)) {
        this.router.navigateByUrl("/login");
        return false;
      }
    }

    this.getAllCompanies().subscribe((data) => {
      if (data && data.length > 0) {
        this.master_Companies = data;
        this.master_Companies = this.master_Companies.filter((item) => {
          return (
            !isNullOrEmpty(item.website) &&
            (!this.authService.isAuthenticated() ||
              this.authService.getUserInfo().company_id == -1 ||
              this.authService.getUserInfo().company_id == item.id)
          );
        });

        let routeExist = this.master_Companies.find((item) => {
          return item.website === `${config.FRONTEND_URL}${website}`;
        });

        if (!isExistOnRoutes || isNullOrEmpty(website)) {
          if (this.authService.isAuthenticated()) {
            if (routeExist !== undefined) {
              return true;
            } else {
              this.router.navigateByUrl("/");
              return false;
            }
          } else {
            if (routeExist !== undefined) {
              this.router.navigateByUrl("/" + website);
            } else {
              this.router.navigateByUrl("/login");
            }

            return false;
          }
        }
      }
    });

    return true;
  }

  getAllCompanies(): Observable<any> {
    return this.http.get(`${this.BACKEND_URL}/res-company`);
  }
}
