import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { customModalUI } from '@common/shared/utils/func.utils';
import { Device } from '@modules/devices/models/device';
import { RemoveDevice } from '@modules/devices/store/device.actions';
import { DevicesState } from '@modules/devices/store/device.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { responseErrorMessage } from "@modules/devices/store/devices.selector";
import { ToastService } from "@common/services/toast.service";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-modal-delete-devices',
  templateUrl: './modal-delete-devices.component.html',
  styleUrls: ['../../containers/devices/devices.component.scss']
})
export class ModalDeleteDevicesComponent implements OnInit {
  @Input() deviceDel!: Device;
  @Output() acceptDeleteDevice$ = new EventEmitter<Device>();

  $errorMessage = this.store.pipe(select(responseErrorMessage));
  
  constructor(
      public activeModal: NgbActiveModal,
      private store: Store<DevicesState>,
      private translateService: TranslateService,
      private toastService: ToastService
    ) {
  }

  ngOnInit(): void {
    customModalUI();

    this.$errorMessage.subscribe((errorMessage) => {
      if (errorMessage == '') {
        this.translateService.get('devices.delete_success').subscribe((res: string) => {
          this.toastService.showSuccess(res);
        });
        this.closeModal() 
      }
      else {
        if(errorMessage != null)
        this.toastService.showError(errorMessage);
      }
    })
  }

  closeModal() {
    this.activeModal.close();
  }

  acceptDeleteDevice(device : Device)
  {
    //remove
    this.store.dispatch(RemoveDevice({ deviceId: device.id }));
  }
}
