import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalService } from "@common/components/_modal";
import { ToastService } from "@common/services/toast.service";
import { customModalUI, isExistedInArrayObjectByKeyAndValue, validateInputLength8 } from "@common/shared/utils/func.utils";
import { Device } from "@modules/devices/models/device";
import { ResetError, UpdateDevice } from "@modules/devices/store/device.actions";
import { DevicesState } from "@modules/devices/store/device.state";
import { responseErrorMessage } from "@modules/devices/store/devices.selector";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-modal-update-devices",
  templateUrl: "./modal-update-devices.component.html",
  styleUrls: ["../../containers/devices/devices.component.scss"],
})
export class ModalUpdateDevicesComponent implements OnInit {
  @Input() deviceFocus!: Device;
  @Input() devices!: Device[];
  @Output() acceptUpdateDevice$ = new EventEmitter<Device>();
  @Input() company: any;
  $errorMessage = this.store.pipe(select(responseErrorMessage));

  private modalService: ModalService;
  form: any;

  constructor(
    modalService: ModalService,
    public activeModal: NgbActiveModal,
    private store: Store<DevicesState>,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.modalService = modalService;
  }

  ngOnInit(): void {
    customModalUI();

    this.form = new FormGroup({
      cd: new FormControl(this.deviceFocus?.cd, [Validators.required]),
      id: new FormControl(this.deviceFocus?.id),
      name: new FormControl(this.deviceFocus?.name, Validators.required),
      uniqueId: new FormControl(this.deviceFocus?.uniqueId, Validators.required),
    });

    this.$errorMessage.subscribe((errorMessage) => {
      if (errorMessage == '') {
        // this.closeLoadingIcon()
        this.translateService.get('devices.update_success').subscribe((res: string) => {
          this.toastService.showSuccess(res);
        });
        this.closeModal() 
      }
      else {
        if(errorMessage != null)
          this.toastService.showError(errorMessage);
      }
    })
  }

  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.store.dispatch(ResetError());
    this.activeModal.close();
  }

  onSubmit = () => {
    if(!validateInputLength8(this.form.value.cd)){
      this.translateService.get('fields.validate_input').subscribe((res: string) => {
        this.toastService.showError("cd: " + res);
      });
      return;
    }
    
    if(isExistedInArrayObjectByKeyAndValue(this.devices, "cd", this.form.value)) {
      this.toastService.showError("cd: " + this.translateService.instant('is_exist'));
      return;
    }

    if (this.form.valid) {
      let device: Device = {
        id: this.form.value.id,
        cd: this.form.value.cd,
        name: this.form.value.name,
        uniqueId: this.form.value.uniqueId,
        groupId: 1,
        companyId: this.deviceFocus.companyId,
        companyName: this.deviceFocus.companyName,
      }
      this.store.dispatch(
        UpdateDevice({
          device: device,
        })
      );
    }
  };
}
