import { Component, OnInit } from '@angular/core';
import { customModalUI, getHeightDiv } from '@common/shared/utils/func.utils';
import { DevicesState } from '@modules/devices/store/device.state';
import { getInfoMoveList } from '@modules/devices/store/tracking-states.selector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
const $ = require('jquery');

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ["../../containers/devices/devices.component.scss"],
})
export class TimeTableComponent implements OnInit {

  displayedTrackingState$ = this.storeDevices.pipe(select(getInfoMoveList));
  displayedTrackingState: any;

  constructor(private activeModal: NgbActiveModal,private storeDevices: Store<DevicesState>,) { }

  ngOnInit(): void {
    customModalUI();
    this.displayedTrackingState$.subscribe(data => {
      this.displayedTrackingState = data;
    })
  }

  getMinutes(time: any) {
    if(time == 0)
      return "less than a ";
    else
      return time;
  }

  closeModal =() => {
    this.activeModal.close();
  }
}
