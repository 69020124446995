import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ModalService } from "@common/components/_modal";
import { ToastService } from "@common/services/toast.service";
import {
  customModalUI,
  isExistedInArrayObjectByKeyAndValue,
  isNotAssignedCompany,
  validateInputLength8,
} from "@common/shared/utils/func.utils";
import { AuthService } from "@modules/auth/services";
import { Device } from "@modules/devices/models/device";
import { AddDevice, ResetError } from "@modules/devices/store/device.actions";
import { DevicesState } from "@modules/devices/store/device.state";
import { responseErrorMessage } from "@modules/devices/store/devices.selector";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-modal-add-devices",
  templateUrl: "./modal-add-devices.component.html",
  styleUrls: ["../../containers/devices/devices.component.scss"],
})
export class ModalAddDevicesComponent implements OnInit {
  @Output() acceptCreateDevice = new EventEmitter<Device>();
  @Input() responseError: any;
  @Input() cd: any;
  @Input() company: any;
  @Input() devices!: Device[];

  $errorMessage = this.store.pipe(select(responseErrorMessage));

  private modalService: any;
  form!: UntypedFormGroup;
  msgError: string = "";

  constructor(
    modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    public toastService: ToastService,
    private store: Store<DevicesState>,
    private translateService: TranslateService
  ) {
    this.modalService = modalService;
  }

  ngOnInit(): void {
    customModalUI();

    this.form = this.formBuilder.group({
      cd: [this.cd, [Validators.required]],
      name: ["", Validators.required],
      uniqueId: ["", Validators.required],
    });

    this.$errorMessage.subscribe((errorMessage) => {
      if (errorMessage == "") {
        // this.closeLoadingIcon()
        this.translateService
          .get("devices.add_success")
          .subscribe((res: string) => {
            this.toastService.showSuccess(res);
          });
        this.closeModal();
      } else {
        if (errorMessage != null) this.toastService.showError(errorMessage);
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.store.dispatch(ResetError());
    this.activeModal.close();
  }

  onSubmit = () => {
    if (isNotAssignedCompany(this.company)) {
      this.translateService
        .get("company.required_company")
        .subscribe((res: string) => {
          this.toastService.showError(res);
        });
      return;
    }

    if (!validateInputLength8(this.form.value.cd)) {
      this.translateService
        .get("fields.validate_input")
        .subscribe((res: string) => {
          this.toastService.showError("cd: " + res);
        });
      return;
    }

    if (
      isExistedInArrayObjectByKeyAndValue(this.devices, "cd", this.form.value)
    ) {
      this.toastService.showError(
        "cd: " + this.translateService.instant("is_exist")
      );
      return;
    }

    this.msgError = "";

    if (this.form.valid) {
      let device: Device = {
        id: 0,
        cd: this.form.value.cd,
        name: this.form.value.name,
        uniqueId: this.form.value.uniqueId,
        groupId: 1,
        companyId: this.company.id,
        companyName: this.company.name,
      };

      this.store.dispatch(AddDevice({ device: device }));
    } else {
      this.translateService.get("required_fields").subscribe((res: string) => {
        this.toastService.showError(res);
      });
    }
  };
}
