import { OnInit, Directive } from '@angular/core';
// import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { WebsocketService } from '@common/shared/services/websocket.service';
import { TrackingState } from '@modules/devices/models/tracking-state';
import { Device } from '@modules/devices/models/device';
import { UpdateDevicesFromSocket } from '@modules/devices/store/device.actions';
import { UpdateTrackingStates } from '@modules/devices/store/tracking-state.actions';
import { updateEventFromSocket } from '@modules/devices/store/event.actions';
import { DevicesState } from '@modules/devices/store/device.state';
import { Alert } from 'selenium-webdriver';
import { ToastService } from '@common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'app-websocket',
})
export class WebsocketComponent implements OnInit {
  private messages: any[] = [];

  constructor(
    private websocketService: WebsocketService,
    // private snackBar: MatSnackBar,
    private store: Store<DevicesState>,
    public toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initIoConnection();
  }

  private initIoConnection(): void {
    this.websocketService.initSocket();

    this.websocketService.onMessage().subscribe((message: any) => {
      const { devices, positions, events } = JSON.parse(message.data);
      if (devices) {
        const devicesArray = devices.map(
          (deviceInfo: any) => new Device(deviceInfo)
        );
        this.store.dispatch(UpdateDevicesFromSocket({ devices: devicesArray }));
      }

      if (positions) {
        const trackingStates = positions.map(
          (stateInfo: any) => new TrackingState(stateInfo)
        );
        this.store.dispatch(UpdateTrackingStates({ trackingStates }));
      }

      if (events) {
        const eventsArray = events.map((eventInfo: string) => new Event(eventInfo));
        this.store.dispatch(updateEventFromSocket({ events: eventsArray }));
      }
    });

    this.websocketService.onOpen().subscribe(() => {
      this.translateService.get('msg.connected').subscribe((res: string) => {
        this.toastService.showSuccess('Websocket ' + res);
      });
    });

    this.websocketService.onClose().subscribe(() => {
      this.translateService.get('msg.disconnected').subscribe((res: string) => {
        this.toastService.showSuccess('Websocket ' + res);
      });
    });
  }
}
