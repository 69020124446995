import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MatDialogModule } from "@angular/material/dialog";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "@common/components/_modal";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
//

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { reducers } from "@modules/auth/store/app.state";
//reducersUsers
import { reducers as reducersUsers } from "@modules/users/store/users.state";
import { CanActivateGuardedRoute } from "@common/shared/guards/can-activate-guarded-route";
import { CanActivateLoginRoute } from "@common/shared/guards/can-activate-login-route";
import { HttpInterceptorProviders } from "@common/shared/http-interceptors";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AuthEffects } from "@modules/auth/store/effects/auth.effects";
import { LoginModule } from "@modules/auth/login/login.module";
import {
  NgbActiveModal,
  NgbModal,
  NgbModule,
  NgbToast,
} from "@ng-bootstrap/ng-bootstrap";
import { AppCommonModule } from "@common/app-common.module";
import { DevicesModule } from "@modules/devices/devices.module";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LocalSettingsService } from "@common/services/LocalSettings.service";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { IconService } from "icon.service";
import { NgbModalDraggableModule } from "ngb-modal-draggable";
import { UserEffects } from "@modules/users/store/effects/user.effects";
import { IconsModule } from "@modules/icons/icons.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    StoreModule.forRoot(reducers, {}),
    StoreModule.forFeature("users", reducersUsers),
    EffectsModule.forRoot([AuthEffects, UserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    NgbModule,
    AppCommonModule,
    DevicesModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgbModalDraggableModule,
  ],
  providers: [
    HttpInterceptorProviders,
    CanActivateGuardedRoute,
    CanActivateLoginRoute,
    NgbToast,
    NgbActiveModal,
    NgbModal,
    LocalSettingsService,
    IconService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faUpload);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
