import { Injectable } from '@angular/core';
import { ApiService } from './company-api.service';
import { Observable } from 'rxjs';
import { Company } from '../../models/company';
import { Currency } from '@modules/organization/models/Currency';
import { Country } from '@modules/organization/models/Country';
import { CountryState } from '@modules/organization/models/CountryState';
import { Partner } from '@modules/organization/models/Partner';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    constructor(private apiService: ApiService) { }

    saveCompanyInfo(company: any): void {
        localStorage.setItem('company', JSON.stringify(company));
    }

    getCompanyInfo(): any {
        const companyInfoStr = localStorage.getItem('company');
        if (!companyInfoStr || companyInfoStr.length === 0) 
          return {} as any;
        const companyInfoJson = JSON.parse(companyInfoStr);
    
        return companyInfoJson;
      }

    public loadAllCurrency(): Observable<Currency[]> {
        return this.apiService.getAllCurency();
    }

    public loadAllCountry(): Observable<Country[]> {
        return this.apiService.getAllCountry();
    }

    public loadAllCountryState(): Observable<CountryState[]> {
        return this.apiService.getCountryState();
    }

    public loadAllCompanies(): Observable<Company[]> {
        return this.apiService.getAllCompany();
    }

    public addCompany(company: Company): Observable<any> {
        return this.apiService.addCompany(company);
    }

    public updateCompany(company: Company): Observable<any> {
        return this.apiService.updateCompany(company);
    }

    public addPartner(partner: Partner): Observable<any> {
        return this.apiService.addPartner(partner);
    }

    public updatePartner(partner: Partner): Observable<any> {
        return this.apiService.updatePartner(partner);
    }

    public deleteCompany(id: number): Observable<any> {
        return this.apiService.deleteEntity(id, Company.name);
    }
}
