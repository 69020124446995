import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchedulesState } from './schedule.reducers';

export const schedulesKey = 'schedules';
export const selectSchedules = createFeatureSelector<SchedulesState>(schedulesKey);

export const selectAllSchedules = createSelector(
    selectSchedules,
    (state: SchedulesState) => state?.schedules
);

export const getSelectedSchedule = createSelector(
    selectSchedules,
    (state: SchedulesState) => state.selectedSchedule
);

// export const getScheduleById = createSelector(
//     selectSchedules,
//     (state: SchedulesState, { scheduleId }) =>
//         state.schedules.find((schedule: Schedule) => schedule.id === scheduleId)
// );
