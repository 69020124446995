import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ChildActivationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import "jquery-ui-dist/jquery-ui";
import { CompanyService } from "@modules/organization/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "now-o-dau";
  constructor(
    public router: Router,
    private titleService: Title,
    private translate: TranslateService,
 
  ) {
    this.initializeApp();

    this.router.events
      .pipe(filter((event) => event instanceof ChildActivationEnd))
      .subscribe((event) => {
        let snapshot = (event as ChildActivationEnd).snapshot;
        while (snapshot.firstChild !== null) {
          snapshot = snapshot.firstChild;
        }
        this.titleService.setTitle(snapshot.data.title || "NowODau");
      });
  }

  initializeApp() {
    this.translate.setDefaultLang("jp");
  }
}
