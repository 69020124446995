import * as companies from './company.reducers';

export interface CompaniesState {
  companiesState: companies.CompaniesState;
}

export const reducers = {
  companies: companies.reducer,
};

