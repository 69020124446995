import { createAction, props } from '@ngrx/store';
import { Schedule } from '../models/schedule';

export enum ScheduleActionTypes {
    LOAD_ALL_SCHEDULES = '[Schedules] Load All Schedules',
    LOAD_ALL_SCHEDULES_SUCCESS = '[Schedules] Load All Success',
    LOAD_ALL_SCHEDULES_FAILURE = '[Schedules] Load All Failure',
    ADD_SCHEDULE = '[Schedules] Add Schedule',
    ADD_SCHEDULE_SUCCESS = '[Schedules] Add Schedule Success',
    ADD_SCHEDULE_FAILURE = '[Schedules] Add Schedule Failure',
    UPDATE_SCHEDULE = '[Schedules] Update Schedule',
    UPDATE_SCHEDULE_SUCCESS = '[Schedules] Update Schedule Success',
    UPDATE_SCHEDULE_FAILURE = '[Schedules] Update Schedule Failure',
    REMOVE_SCHEDULE = '[Schedules] Remove Schedule',
    REMOVE_SCHEDULE_SUCCESS = '[Schedules] Remove Schedule Success',
    REMOVE_SCHEDULE_FAILURE = '[Schedules] Remove Schedule Failure',
    SELECT_SCHEDULE = '[Schedules] Select Schedule',
}

export const LoadAllSchedules = createAction(ScheduleActionTypes.LOAD_ALL_SCHEDULES);

export const LoadAllSchedulesSuccess = createAction(
    ScheduleActionTypes.LOAD_ALL_SCHEDULES_SUCCESS,
    props<{ schedules: Schedule[] }>()
);

export const LoadAllSchedulesFailure = createAction(
    ScheduleActionTypes.LOAD_ALL_SCHEDULES_FAILURE,
    props<{ error: string }>()
);

export const AddSchedule = createAction(
    ScheduleActionTypes.ADD_SCHEDULE,
    props<{ schedule: Schedule }>()
);

export const AddScheduleSuccess = createAction(
    ScheduleActionTypes.ADD_SCHEDULE_SUCCESS,
    props<{ schedule: any }>()
);

export const AddScheduleFailure = createAction(
    ScheduleActionTypes.ADD_SCHEDULE_FAILURE,
    props<{ error: string }>()
);

export const UpdateSchedule = createAction(
    ScheduleActionTypes.UPDATE_SCHEDULE,
    props<{ schedule: Schedule }>()
);

export const UpdateScheduleSuccess = createAction(
    ScheduleActionTypes.UPDATE_SCHEDULE_SUCCESS,
    props<{ schedule: any }>()
);

export const UpdateScheduleFailure = createAction(
    ScheduleActionTypes.UPDATE_SCHEDULE_FAILURE,
    props<{ error: string }>()
);

export const RemoveSchedule = createAction(
    ScheduleActionTypes.REMOVE_SCHEDULE,
    props<{ scheduleId: number }>()
);

export const RemoveScheduleSuccess = createAction(
    ScheduleActionTypes.REMOVE_SCHEDULE_SUCCESS,
    props<{ scheduleId: number }>()
);

export const RemoveScheduleFailure = createAction(
    ScheduleActionTypes.REMOVE_SCHEDULE_FAILURE,
    props<{ error: string }>()
);

export const SelectSchedule = createAction(
    ScheduleActionTypes.SELECT_SCHEDULE,
    props<{ scheduleId: number }>()
);