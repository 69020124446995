import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  CompanyActionTypes,
  AddCompanySuccess,
  AddCompanyFailure,
  UpdateCompanySuccess,
  UpdateCompanyFailure,
  RemoveCompanySuccess,
  RemoveCompanyFailure,
  LoadAllCompaniesSuccess,
  LoadAllCompaniesFailure,
  LoadAllCurrencyFailure,
  LoadAllCountrySuccess,
  LoadAllCountryFailure,
  LoadAllCountryStateSuccess,
  LoadAllCountryStateFailure,
  LoadAllCurrencySuccess,
  AddPartnerSuccess,
  AddPartnerFailure,
  UpdatePartnerSuccess,
  UpdatePartnerFailure,
  LoadAllPartnersFailure,
  LoadAllPartnersSuccess,
  RemovePartnerFailure,
  RemovePartnerSuccess,
} from "./company.actions";
import { mergeMap, catchError, map, tap } from "rxjs/operators";
import { CompanyService } from "../../services/company/company.service";
import { Company } from "../../models/company";
import { ToastService } from "@common/services/toast.service";
import { Partner } from "@modules/organization/models/Partner";
import { ContactCompanyService } from "@modules/organization/services/contact-company/contact-company.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class CompanyEffects {
  constructor(
    private actions: Actions,
    private companylService: CompanyService,
    private contactCompanyService: ContactCompanyService,
    public toastService: ToastService,
    private translateService: TranslateService
  ) {}

  //#region Fetching Currency Effects
  LoadAllCurrency = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.LOAD_ALL_CURRENCY),
      mergeMap(() => {
        return this.companylService.loadAllCurrency().pipe(
          map((currency) => {
            return LoadAllCurrencySuccess({ currency });
          }),
          catchError((error) => {
            return of(LoadAllCurrencyFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllCurrencyFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.LOAD_ALL_CURRENCY_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Fetching Country Effects
  LoadAllCountry = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.LOAD_ALL_COUNTRY),
      mergeMap(() => {
        return this.companylService.loadAllCountry().pipe(
          map((country) => {
            return LoadAllCountrySuccess({ country });
          }),
          catchError((error) => {
            return of(LoadAllCountryFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllCountryFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.LOAD_ALL_COUNTRY_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Fetching Country state Effects
  LoadAllCountryState = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.LOAD_ALL_COUNTRY_STATE),
      mergeMap(() => {
        return this.companylService.loadAllCountryState().pipe(
          map((countryState) => {
            return LoadAllCountryStateSuccess({ countryState });
          }),
          catchError((error) => {
            return of(LoadAllCountryStateFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllCountryStateFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.LOAD_ALL_COUNTRY_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Fetching Companies Effects
  LoadAllCompanies = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.LOAD_ALL_COMPANIES),
      mergeMap(() => {
        return this.companylService.loadAllCompanies().pipe(
          map((companies) => {
            return LoadAllCompaniesSuccess({ companies });
          }),
          catchError((error) => {
            return of(LoadAllCompaniesFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllCompaniesFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.LOAD_ALL_COMPANIES_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Registering Company effects
  AddCompany = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.ADD_COMPANY),
      mergeMap((args: any) => {
        const company: Company = args.company;
        return this.companylService.addCompany(company).pipe(
          map((newInfo) => {
            // const newCompany = new Company(newInfo);
            return AddCompanySuccess({ company: newInfo });
          }),
          catchError((error) => {
            return of(AddCompanyFailure({ error }));
          })
        );
      })
    );
  });

  AddCompanyFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.ADD_COMPANY_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Updating Company effects
  UpdateCompany = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.UPDATE_COMPANY),
      mergeMap((args: any) => {
        return this.companylService.updateCompany(args.company).pipe(
          map((newCompany) => {
            return UpdateCompanySuccess({ company: newCompany });
          }),
          catchError((response) => {
            return of(UpdateCompanyFailure({ error: response.error }));
          })
        );
      })
    );
  });

  UpdateCompanyFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.UPDATE_COMPANY_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  LoadAllPartner = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.LOAD_ALL_PARTNERS),
      mergeMap(() => {
        return this.contactCompanyService.loadAllContactsCompany().pipe(
          map((partners) => {
            return LoadAllPartnersSuccess({ partners });
          }),
          catchError((error) => {
            return of(LoadAllPartnersFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllPartnerFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.LOAD_ALL_PARTNERS_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error);
        })
      );
    },
    { dispatch: false }
  );

  //#region Registering Partner effects
  AddPartner = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.ADD_PARTNER),
      mergeMap((args: any) => {
        const partner: Partner = args.partner;
        return this.companylService.addPartner(partner).pipe(
          map((newInfo) => {
            //success
            this.translateService
              .get("contacts.add_success")
              .subscribe((res: string) => {
                this.toastService.showSuccess(res);
              });
            return AddPartnerSuccess({ partner: newInfo });
          }),
          catchError((error) => {
            return of(AddPartnerFailure({ error }));
          })
        );
      })
    );
  });

  AddPartnerFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.ADD_PARTNER_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args.error?.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion

  //#region Updating Partner effects
  UpdatePartner = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.UPDATE_PARTNER),
      mergeMap((args: any) => {
        return this.companylService.updatePartner(args.partner).pipe(
          map((newPartner) => {
            //success
            this.translateService
              .get("contacts.update_success")
              .subscribe((res: string) => {
                this.toastService.showSuccess(res);
              });
            return UpdatePartnerSuccess({ partner: newPartner });
          }),
          catchError((response) => {
            return of(UpdatePartnerFailure({ error: response.error }));
          })
        );
      })
    );
  });

  UpdatePartnerFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.UPDATE_PARTNER_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  //remove Partner
  RemovePartner = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.REMOVE_PARTNER),
      mergeMap(({ id }) => {
        return this.contactCompanyService.deletePartner(id).pipe(
          map(() => {
            //success
            this.translateService.get("contacts.delete_success").subscribe((res: string) => {
              this.toastService.showSuccess(res);
            });
            return RemovePartnerSuccess({ id });
          }),
          catchError((response) => {
            return of(RemovePartnerFailure({ error: response.error }));
          })
        );
      })
    );
  });

  RemovePartnerFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.REMOVE_PARTNER_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  //#endregion
  //#region Removing Company effects
  RemoveCompany = createEffect(() => {
    return this.actions.pipe(
      ofType(CompanyActionTypes.REMOVE_COMPANY),
      mergeMap(({ id }) => {
        return this.companylService.deleteCompany(id).pipe(
          map(() => {
            //success
            this.translateService
              .get("contacts.delete_success")
              .subscribe((res: string) => {
                this.toastService.showSuccess(res);
              });
            return RemoveCompanySuccess({ id });
          }),
          catchError((response) => {
            return of(RemoveCompanyFailure({ error: response.error }));
          })
        );
      })
    );
  });

  RemoveCompanyFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(CompanyActionTypes.REMOVE_COMPANY_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );
  //#endregion
}
