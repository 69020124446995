export function addFilter(dataSource: any) {
  dataSource.filterPredicate = (data: any, filtersJson: string) => {
    const matchFilter: boolean[] = [];
    const filters = JSON.parse(filtersJson);

    filters.forEach((filter: any) => {
      // const val = data[filter.id] === null ? "" : data[filter.id];

      let val = "";
      if (Object.values(data).toString().toLowerCase().includes(filter.value)) {
        val = filter.value === null ? "" : filter.value;
      }

      matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
    });
    return matchFilter.every(Boolean);
  };
}

//filter array of objects by key value
export function filterByKey(array: any, key: string, value: string) {
  return array.filter((obj: any) => obj[key] == value);
}

//filter like in sql
export function filterLike(array: any[], key: string, value: string) {
  return array.filter((obj) =>
    obj[key].toLowerCase().includes(value.toLowerCase())
  );
}

//filter muiltiple keys
export function filterMultipleKeys(
  array: any[],
  keys: string[],
  value: string
) {
  return array.filter((obj) =>
    keys.some((key) =>
      obj[key]
        ?.toString()
        .toLowerCase()
        .includes(value?.toString().toLowerCase())
    )
  );
}

//check string is null or empty
export function isNullOrEmpty(value: string) {
  return value == null || value == "";
}

export function compare(v1: number | string, v2: number | string) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

export function sort(array: any[], column: string, direction: string): any[] {
  return [...array].sort((a, b) => {
    const res = compare(a[column], b[column]);
    return direction === "asc" || direction === "" ? res : -res;
  });
}

export function sortConcatString(
  array: any[],
  columns: string[],
  direction: string
): any[] {
  return [...array].sort((a, b) => {
    let _a = "";
    let _b = "";
    columns.forEach((column) => {
      _a += a[column];
      _b += b[column];
    });

    const res = compare(_a, _b);
    return direction === "asc" || direction === "" ? res : -res;
  });
}

export function sortMultipleColumn(array: any[], columns: any[]) {}

//cut string by begin string and end string
export function cutString(str: string, begin: string, end: string): string {
  return str.substring(str.indexOf(begin) + begin.length, str.indexOf(end));
}

//convert string to date
export function convertStringToDate(str: string): Date {
  return new Date(str);
}

//format date time
export function formatDateTime(date: Date): string {
  const d = new Date(date);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  const year = d.getFullYear();
  const hour = `0${d.getHours()}`.slice(-2);
  const minute = `0${d.getMinutes()}`.slice(-2);
  const second = `0${d.getSeconds()}`.slice(-2);
  return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
}

//new current time
export function getCurrentTime() {
  let current_time = new Date();
  let hour = current_time.getHours();
  let minute = current_time.getMinutes();
  let seconds = current_time.getSeconds();
  let time = hour + ":" + minute + ":" + seconds;
  return time;
}

export function convertMillisecondsToDate(milliseconds: any) {
  var date = new Date(milliseconds);
  if (isValidDate(date)) {
    return date;
  } else {
    return new Date();
  }
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN((d as unknown) as number);
}

//filter array of object by key and value
export function filterArray(array: any[], key: string, value: number) {
  return array.filter((item) => item[key] == value);
}

export function customModalUI() {
  const $ = require("jquery");
  $(document).ready(function () {
    let modalContent = $(".modal-content");
    let modalHeader = $(".modal-header");

    modalHeader.css("cursor", "move");
    modalContent.css("border", "none");
  });
}

export function canResizeModalUI() {
  const $ = require("jquery");
  $(document).ready(function () {
    let modalContent = $(".modal-content");
    modalContent.css("background", "transparent");
  });
}

export function convertStringToArray(str: string) {
  let arr: Array<string> = str.split(", ");
  return arr;
}

export function //pad left to string with 0 character to 5 length string.
padLeft(str: string, length: number) {
  return (Array(length + 1).join("0") + str).slice(-length);
}

//cut top string with length
export function cutTopString(str: string, length: number) {
  return str.substring(0, length);
}

export function changeSizeDiv(name: string, size: string) {
  $(name).css("width", size);
}

//get width div by id
export function getWidthDiv(id: string) {
  return document.getElementById(id)?.clientWidth?.toString() + "px";
}

//get height div by id
export function getHeightDiv(id: string) {
  return document.getElementById(id)?.clientHeight?.toString() + "px";
}

//remove element of array in another array by key and value of element in array of array and array of element to remove
export function removeElementOfArray(
  array: any[],
  key: string,
  value: any,
  arrayRemove: any[]
) {
  let index = array.findIndex((item) => item[key] == value);
  array.splice(index, 1, arrayRemove);
}

//order by multiple column in array of object like order by column1 asc, column2 desc in sql server
export function orderByMultipleColumn(
  array: Record<string, string>[],
  columns: Record<string, string>[]
) {
  return array.sort((a: any, b: any) => {
    let i = 0,
      result = 0;
    while (i < columns.length && result === 0) {
      result =
        compare(a[columns[i].column], b[columns[i].column]) *
        (columns[i].direction === "desc" ? -1 : 1);
      i++;
    }
    return result;
  });
}

//add column to json object
export function addColumnToObjectOfArray(
  column: string,
  value: any,
  arrayObject: Array<any>
) {
  if (arrayObject.length > 0) {
    let assignList: any = [];

    arrayObject.forEach((item: any) => {
      if (typeof item[column] == "undefined") {
        const newObj = Object.assign({ [column]: value }, item);

        assignList.push(newObj);
      }
    });

    arrayObject = assignList;
  }

  return arrayObject;
}

export function changeValueColumnObjectOfArray(
  column: string,
  value: any,
  arrayObject: Array<any>,
  arrayObjChanged: Array<any> = [],
  key: string = "",
  keyValue: any = "id"
) {
  if (arrayObject.length > 0) {
    let assignList: any = [];
    if (typeof arrayObject[0][column] != "undefined") {
      arrayObject.map((item: any) => {
        if (key != "" && arrayObjChanged.length > 0) {
          let obj = arrayObjChanged.find(
            (obj: any) => obj[key] == item[keyValue]
          );
          if (obj) {
            item[column] = value;
          }
        } else {
          item[column] = value;
        }
        assignList.push(item);
      });
    }

    arrayObject = assignList;
  }

  return arrayObject;
}

//validate input length 8 three character at beginning of string and 5 number at end of string
export function validateInputLength8(str: string) {
  let regex = /^[a-zA-Z]{3}[0-9]{5}$/;
  return regex.test(str);
}

//check value existed in array object
export function isExistedInArrayObjectByKeyAndValue(
  arrObj: any[],
  key: string,
  obj: any
) {
  if (arrObj && arrObj.length > 0) {
    let arr = filterByKey(arrObj, key, obj.cd);
    //different itself

    if (arr && arr.length == 1 && arr[0].id != obj.id) {
      return true;
    }
  }
  return false;
}

export function getLimit1SortDescByColumn(arrObj: any[], column: string) {
  if (arrObj && arrObj.length > 0) {
    let arr = sort(arrObj, column, "desc");
    return arr[0];
  }
  return null;
}

//get time zone Asia/Ho_Chi_Minh
export function getTimeZone() {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}

//ignore a list in another list
export function ignoreListInListByKey(
  list: Array<any>,
  ignoreList: Array<any>,
  key: string
) {
  let listIgnore = ignoreList.map((item) => item[key]);
  let listResult = list.filter((item) => !listIgnore.includes(item[key]));
  return listResult;
}

//remove all Character's at the start of the string
export function removeAllCharacterAtStartOfString(
  str: string,
  character: string
) {
  let regex = new RegExp("^" + character + "+");
  return str.replace(regex, "");
}

//remove all Character's at the start of the string
export function isNotAssignedCompany(company: any) {
  if (company == null || company.id == -1) {
    return true;
  }
  return false;
}

export async function processFile(file: File) {
  let logo_web = "";

  try {
    let arraybBuffer = await readFileAsync(file);
    const dataBase64 = arrayBufferToBase64(arraybBuffer as ArrayBuffer);
    // this.imagePath = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,' + dataBase64);
    logo_web = dataBase64;
  } catch (error) {}

  return logo_web;
}

function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

function arrayBufferToString(arraybBuffer: any, decoderType = "utf-8") {
  let decoder = new TextDecoder(decoderType);
  return decoder.decode(arraybBuffer);
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

//is undefined or null
export function isNullOrUndefined(value: any) {
  return value === undefined || value === null;
}


