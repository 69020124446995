import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import config from "@common/shared/config/config";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  private BACKEND_URL = config.BACKEND_URL;
  private baseUrl = `${this.BACKEND_URL}/res-company`;

  constructor(private http: HttpClient) {}

  upload(file: any,id: any): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();



    formData.append("file", file);
    // byte[] logo_web
    let data = {
      logo_web: file,
      id: id,
    }

    // const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
    //   reportProgress: true,
    //   responseType: 'json',
    // });

    // return this.http.request(req);

    return new Observable<any>((subscriber) => {
      this.http.put(`${this.baseUrl}/upload`, data,{ observe: 'response' }
      )
      .subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }
}
