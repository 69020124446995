import { createReducer, on } from '@ngrx/store';
import { Device } from '../models/device';
import {
  AddDeviceSuccess,
  AddDeviceFailure,
  UpdateDeviceSuccess,
  UpdateDeviceFailure,
  RemoveDeviceSuccess,
  RemoveDeviceFailure,
  LoadAllDevicesSuccess,
  SelectDevice,
  ClearSelectedDevice,
  UpdateDevicesFromSocket,
  ToggleDeviceName,
  ResetError,
} from './device.actions';

export interface DevicesState {
  // TODO: Do I need | []?
  devices: Device[] | [];
  selectedDevice: number | null;
  errorMessage: string | null;
  isShowName: boolean;
}

export const initialState: DevicesState = {
  devices: [],
  selectedDevice: null,
  errorMessage: null,
  isShowName: false,
};

const _devicesReducer = createReducer(
  initialState,
  on(LoadAllDevicesSuccess, (state, { devices }) => ({
    ...state,
    devices,
    errorMessage: null,
  })),
  on(AddDeviceSuccess, (state, { device }) => ({
    ...state,
    devices: [...state.devices, device],
    errorMessage: '',
  })),
  on(AddDeviceFailure, (state, { error }) => ({
    ...state,
    errorMessage: error.error,
  })),
  on(UpdateDeviceSuccess, (state, { device }) => ({
    ...state,
    // Create new array that excludes target device, and add updated device
    devices: [
      ...state.devices.filter(
        (curDevice: Device) => curDevice.id !== device.id
      ),
      device,
    ],
    errorMessage: '',
  })),
  on(UpdateDeviceFailure, (state, { error }) => ({
    ...state,
    errorMessage: error.error,
  })),
  on(RemoveDeviceSuccess, (state, { deviceId }) => ({
    ...state,
    devices: state.devices.filter(
      (curDevice: Device) => curDevice.id !== deviceId
    ),
    errorMessage: '',
  })),
  on(RemoveDeviceFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),
  on(UpdateDevicesFromSocket, (state, { devices }) => {
    const mergedDeviceArray = [...state.devices];
    mergedDeviceArray.forEach((device, index) => {
      devices.forEach((updatedDevice) => {
        if (device.id === updatedDevice.id)
          mergedDeviceArray[index] = updatedDevice;
      });
    });
    return {
      ...state,
      devices: mergedDeviceArray,
    };
  }),
  on(SelectDevice, (state, { deviceId }) => ({
    ...state,
    selectedDevice: deviceId,
  })),
  on(ClearSelectedDevice, (state) => ({
    ...state,
    selectedDevice: null,
  })),
  on(ToggleDeviceName, (state, { isShow }) => ({
    ...state,
    isShowName: isShow,
  })),
  on(ResetError, (state) => ({
    ...state,
    errorMessage: null,
  })),
);

export function reducer(state: DevicesState | undefined, action : any) {
  return _devicesReducer(state, action);
}
