import {
  LogInSuccess,
  LogInFailure,
  LogOutSuccess,
  LogOutFailure,
} from '../actions/auth.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { SystemUser } from '../../models/system-user';

export interface State {
  isAuthenticated: boolean;
  user: SystemUser | null;
  errorMessage: string | boolean | null;
}

export const initialState: State = {
  isAuthenticated: (localStorage.getItem('user') != null && localStorage.getItem('user')!.length > 0),
  user: null,
  errorMessage: null,
};

const _authReducer = createReducer(
  initialState,
  on(LogInSuccess, (state, { user }) => ({
    ...state,
    isAuthenticated: true,
    user,
    errorMessage: null,
  })),
  on(LogInFailure, (state, { error }) => ({
    ...state,
    errorMessage: true,
  })),
  on(LogOutSuccess, (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    errorMessage: null,
  })),
  on(LogOutFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  }))
);

export function reducer(state: State | undefined, action: any) {
  return _authReducer(state, action);
}
