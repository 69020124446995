import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { customModalUI } from '@common/shared/utils/func.utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['../../../../app/app.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() title: string = '';

  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    customModalUI()
  }

  closeModal() {
    this.activeModal.close();
  }
}
