import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Device } from '../models/device';
import config from '@common/shared/config/config';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private BACKEND_URL = config.BACKEND_URL;
  private DEVICE_URL = `${this.BACKEND_URL}/devices`;
  private MODEL_TO_URL_MAP: any;
  

  constructor(private http: HttpClient) {
    this.MODEL_TO_URL_MAP = {
      [`${Device.name}`]: `${this.DEVICE_URL}`,
    };
   }

  //#region DEVICES
  /**
   *
   */
  public getAllDevices(): Observable<Device[]> {
    return new Observable<Device[]>((subscriber) => {
      this.http.get(`${this.DEVICE_URL}`).subscribe(
        (devices: any) => {
          subscriber.next(devices.map((deviceInfo : any) => new Device(deviceInfo)));
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param name
   * @param uniqueId
   */
  public addDevice(device: any): Observable<Device> {
    return new Observable<Device>((subscriber) => {
      this.http.post(this.DEVICE_URL, device).subscribe(
        (deviceInfo: any) => {
          subscriber.next(new Device(deviceInfo));
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param id
   * @param name
   * @param uniqueId
   */
  public updateDevice(device: Device): Observable<Device> {
    return new Observable<Device>((subscriber) => {
      this.http
        .put(`${this.DEVICE_URL}/${device.id}`, device)
        .subscribe(
          (deviceInfo: any) => {
            subscriber.next(new Device(deviceInfo));
            subscriber.complete();
          },
          (error) => {
            subscriber.error(error);
          }
        );
    });
  }
  //#endregion

  public deleteEntity(id: number, className: string): Observable<any> {
    let url: string = this.MODEL_TO_URL_MAP[className]

    if (!url || url.length === 0) return {} as Observable<any>;

    return new Observable<any>((subscriber) => {
      this.http.delete(`${url}/${id}`).subscribe(
        () => {
          subscriber.next('ok');
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
