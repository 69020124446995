import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToastService } from "@common/services/toast.service";
import config from "@common/shared/config/config";
import {
  convertStringToArray,
  removeAllCharacterAtStartOfString,
} from "@common/shared/utils/func.utils";
import { AuthService } from "@modules/auth/services";
import {
  sideNavItems,
  sideNavItems20,
  sideNavSections,
} from "@modules/navigation/data";
import { SideNavItems } from "@modules/navigation/models";
import { NavigationService } from "@modules/navigation/services";
import { CompanyService } from "@modules/organization/services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-layout-dashboard",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./layout-dashboard.component.html",
  styleUrls: ["layout-dashboard.component.scss"],
})
export class LayoutDashboardComponent implements OnInit, OnDestroy {
  @Input() static = false;
  @Input() light = false;
  @Input() id: any;
  @HostBinding("class.sb-sidenav-toggled") sideNavHidden = false;
  isGuest = !this.authService.isAuthenticated();

  subscription: Subscription = new Subscription();

  text = this.authService.getUserInfo().permission;

  sideNavItems = this.getMenuByPermission(
    this.text ? convertStringToArray(this.text) : []
  )[0] as SideNavItems;
  sideNavSections = this.getMenuByPermission(
    this.text ? convertStringToArray(this.text) : []
  )[1];

  sidenavStyle = "sb-sidenav-dark";
  getUrl: string = "";
  master_Companies: any;

  constructor(
    public navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    public companyService: CompanyService,
    public toastsService: ToastService
  ) {}

  ngOnInit() {
    this.toastsService.close();

    if (this.light) {
      this.sidenavStyle = "sb-sidenav-light";
    }
    this.subscription.add(
      this.navigationService.sideNavVisible$().subscribe((isVisible) => {
        this.sideNavHidden = !isVisible;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  assignWebsiteToSubMenu(menu: any, splitUrl: any, haveWebsite: any) {
    for (let key in menu) {
      if (menu[key]?.submenu) {
        this.assignWebsiteToSubMenu(menu[key]?.submenu, splitUrl, haveWebsite);
      } else {
        if (haveWebsite) {
          menu[key] = {
            ...menu[key],
            link: `/${splitUrl[0]}${menu[key]?.default}`,
          };
        }
      }
    }
  }

  //lower case function
  toLowerCase(str: string) {
    return str;
  }

  getMenuByPermission(
    permission: Array<string>,
    listItems = sideNavItems,
    listSession = sideNavSections
  ) {
    let newMenu: any = {
      dashboard: listItems["dashboard"],
    };

    let newSession: Array<any> = [
      {
        text: "MENU",
        items: ["dashboard"],
      },
    ];

    if (!this.isGuest) {
      if (permission.includes("10") || permission.includes("20")) {
        newMenu = listItems;
        newSession[0].items = listSession[0].items;
      } else if (permission.includes("30")) {
        newMenu = sideNavItems20;
        newSession[0].items = listSession[1].items;
      }
    }

    return [newMenu, newSession];
  }

  receiveMasterCompaniesFromTop($event: any) {
    this.master_Companies = $event;

    this.getUrl = removeAllCharacterAtStartOfString(this.router.url, "/");
    let splitUrl = this.getUrl.split("/");
    let itemHaveChildren = this.router.config.find((item) => {
      return (
        item?.children &&
        item.children.find((child) => child.path == splitUrl[1])
      );
    });

    let isExist =
      this.master_Companies.find((item: any) => {
        return item.website == `${config.FRONTEND_URL}${splitUrl[0]}`;
      }) != undefined;

    let haveWebsite = splitUrl.length > 1 && itemHaveChildren && isExist;

    this.assignWebsiteToSubMenu(this.sideNavItems, splitUrl, haveWebsite);

    for(let key in this.sideNavItems){
      let isExpended = this.sideNavItems[key]?.submenu?.find((item: any) => item.link == "/" + this.getUrl) != undefined;
      if (isExpended) this.sideNavItems[key].expanded = true;
  }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
