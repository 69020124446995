import { createReducer, on } from '@ngrx/store';
import { AddCompanySuccess, AddCompanyFailure, UpdateCompanySuccess, UpdateCompanyFailure, RemoveCompanySuccess, RemoveCompanyFailure, LoadAllCompaniesSuccess, ResetError, LoadAllCurrencySuccess, LoadAllCountrySuccess, LoadAllCountryStateSuccess, SelectedCompany, AddPartnerSuccess, AddPartnerFailure, UpdatePartnerSuccess, UpdatePartnerFailure, LoadAllPartnersSuccess, RemovePartnerSuccess} from './company.actions';
import { Company } from '../../models/company';
import { Currency } from '@modules/organization/models/Currency';
import { Country } from '@modules/organization/models/Country';
import { CountryState } from '@modules/organization/models/CountryState';
import { Partner } from '@modules/organization/models/Partner';

export interface CompaniesState {
    // TODO: Do I need | []?
    companies: Company[] | [];
    partner: Partner[] | [];
    currency: Currency[] | [];
    country: Country[] | [];
    countryState: CountryState[] | [];
    errorMessage: string | null;
    selectedCompanyId: number;
    selectedCompany: any;
    company: Company | null;
    partner_item: any;
}

export const initialState: CompaniesState = {
    companies: [],
    partner: [],
    currency: [],
    country: [],
    countryState: [],
    errorMessage: null,
    company: null,
    selectedCompanyId: -1,
    selectedCompany:null,
    partner_item: null
};

const _companiesReducer = createReducer(
    initialState,

    //LoadAllCurrencySuccess
    on(LoadAllCurrencySuccess, (state, { currency }) => ({
        ...state,
        currency: currency,
        errorMessage: null,
    })),

    //LoadAllCountrySuccess
    on(LoadAllCountrySuccess, (state, { country }) => ({
        ...state,
        country: country,
        errorMessage: null,
    })),

    //LoadAllCountryStateSuccess
    on(LoadAllCountryStateSuccess, (state, { countryState }) => ({
        ...state,
        countryState: countryState,
        errorMessage: null,
    })),

    //LoadAllCompaniesSuccess
    on(LoadAllCompaniesSuccess, (state, { companies }) => ({
        ...state,
        companies: companies,
        errorMessage: null,
    })),

    //LoadAllCompaniesSuccess
    on(LoadAllCompaniesSuccess, (state, { companies }) => ({
        ...state,
        companies: companies,
        errorMessage: null,
    })),
    
    on(AddCompanySuccess, (state, { company }) => ({
        ...state,
        companies: [...state.companies, company],
        company: company,
        errorMessage: '',
    })),
    on(AddCompanyFailure, (state, { error }) => ({
        ...state,
        errorMessage: error,
    })),
    on(UpdateCompanySuccess, (state, { company }) => ({
        ...state,
        companies: [
            ...state.companies.filter(
                (currentItem: Company) => currentItem.id !== company.id
            ),
            company,
        ],
        errorMessage: '',
    })),
    on(UpdateCompanyFailure, (state, { error }) => ({
        ...state,
        errorMessage: error,
    })),
    //loadAllPartnersSuccess
    on(LoadAllPartnersSuccess, (state, { partners }) => ({
        ...state,
        partner: partners,
        errorMessage: null,
    })),
    on(AddPartnerSuccess, (state, { partner }) => ({
        ...state,
        partner: [...state.partner, partner],
        partner_item: partner,
        errorMessage: '',
    })),
    on(AddPartnerFailure, (state, { error }) => ({
        ...state,
        errorMessage: error,
    })),
    on(UpdatePartnerSuccess, (state, { partner }) => ({
        ...state,
        partner: [
            ...state.partner.filter(
                (currentItem: Partner) => currentItem.id !== partner.id
            ),
            partner,
        ],
        errorMessage: '',
    })),
    on(UpdatePartnerFailure, (state, { error }) => ({
        ...state,
        errorMessage: error,
    })),
    //    //remove Partner
    on(RemovePartnerSuccess, (state, { id }) => ({
        ...state,
        partner: state.partner.filter(
            (currentItem: Partner) => currentItem.id !== id
        ),
        errorMessage: '',
    })),
    on(RemoveCompanySuccess, (state, { id }) => ({
        ...state,
        companies: state.companies.filter(
            (currentItem: Company) => currentItem.id !== id
        ),
        errorMessage: '',
    })),
    on(RemoveCompanyFailure, (state, { error }) => ({
        ...state,
        errorMessage: error,
    })),

    on(ResetError, (state) => ({
        ...state,
        errorMessage: null,
      })),

    on(SelectedCompany, (state,{ id,selectedCompany }) => ({
        ...state,
        selectedCompanyId: id,
        selectedCompany:selectedCompany
      }))
);

export function reducer(state: CompaniesState | undefined, action : any) {
    return _companiesReducer(state, action);
}
