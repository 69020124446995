import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigurationInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const target = req.url.split('/api/')[1];
    const formUrlencodedRequired = ['session'];
    const type = formUrlencodedRequired.some((endpoint) => endpoint === target)
      ? 'application/x-www-form-urlencoded'
      : 'application/json';
    req = req.clone({
      headers: new HttpHeaders().set('Content-Type', type),
      withCredentials: true,
    });
    return next.handle(req);
  }
}
