import { TrackingState } from '@modules/devices/models/tracking-state';
import { createReducer, on } from '@ngrx/store';
import {
  UpdateTrackingStates,
  AddPositionToDisplayList,
  RemovePositionFromDisplayList,
  AddInfoMoveList,
  RemoveInfoMoveList,
} from './tracking-state.actions';

export interface TrackingStates {
  // TODO: Do I need | []?
  positions: TrackingState[] | [];
  // TODO: Do I need | []?
  displayedPositions: TrackingState[] | [];
  displayedInfoMove: any[] | [];
}

export const initialState: TrackingStates = {
  positions: [],
  displayedPositions: [],
  displayedInfoMove: [],
};

const _trackingStateReducer = createReducer(
  initialState,
  on(UpdateTrackingStates, (state, { trackingStates }) => ({
    ...state,
    positions: trackingStates,
  })),
  on(AddPositionToDisplayList, (state, { deviceId }) => {
    const targetPostition = state.positions.find(
      (position: TrackingState) => position.deviceId === deviceId
    );
    if (!targetPostition) return { ...state };
    return {
      ...state,
      displayedPositions: [...state.displayedPositions, targetPostition],
    };
  }),
  on(RemovePositionFromDisplayList, (state, { deviceId }) => ({
    ...state,
    displayedPositions: state.displayedPositions.filter(
      (position: TrackingState) => position.deviceId !== deviceId
    ),
  })),
  on(AddInfoMoveList, (state, { InfoMove }) => {
    if (!InfoMove) return { ...state };

    return {
      ...state,
      displayedInfoMove: [...state.displayedInfoMove, InfoMove],
    };
  }),
  on(RemoveInfoMoveList, (state, { routeId }) => ({
    ...state,
    displayedInfoMove: state.displayedInfoMove?.filter(
      (InfoMove: any) => InfoMove?.route_id !== routeId)
  }))
);

export function reducer(state: TrackingStates | undefined, action: any) {
  return _trackingStateReducer(state, action);
}
