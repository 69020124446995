import { CardViewDetailsComponent } from "./card-view-details/card-view-details.component";
import { CardComponent } from "./card/card.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ToastComponent } from "./toast/toast.component";
import { TableImageComponent } from "./table-image/table-image.component";
import { PopupComponent } from "./popup/popup.component";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { ConfirmPopupComponent } from "./confirm-popup/confirm-popup.component";
import { ChangeLanguageComponent } from '@modules/app-common/components/change-language/change-language.component';


export const components = [
  CardComponent,
  CardViewDetailsComponent,
  ToastComponent,
  SpinnerComponent,
  TableImageComponent,
  PopupComponent,
  ImageUploadComponent,
  ConfirmPopupComponent,
  ChangeLanguageComponent
];

export * from "./card/card.component";
export * from "./card-view-details/card-view-details.component";
export * from "./Toast/toast.component";
export * from "./spinner/spinner.component";
export * from "./table-image/table-image.component";
export * from "./popup/popup.component";
export * from "./image-upload/image-upload.component";
export * from "./confirm-popup/confirm-popup.component";
export * from "./change-language/change-language.component";