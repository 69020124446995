import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { PermissionService } from '../services/permission.service';
import {selectUserLogin} from '@modules/auth/store/auth.selector';

@Injectable()
export class FeatureGuard implements CanActivate {

  constructor(protected store: Store,
              protected router: Router,
              protected permissionService: PermissionService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectUserLogin),
      map((user) => {
        // if (!!user && this.permissionService.checkPermission(user, route.data.feature, route.data.permission)) {
        //   return true;
        // }
        return this.router.parseUrl('dashboard');
      }),
    );
  }
}
