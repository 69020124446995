import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompaniesState } from './company.reducers';

export const companiesKey = 'companies';
export const selectCompanies = createFeatureSelector<CompaniesState>(companiesKey);

export const selectAllCompanies = createSelector(
    selectCompanies,
    (state: any) => state?.companies.companies
);

export const selectAllPartners = createSelector(
    selectCompanies,
    (state: any) => state?.companies.partner
);

//errorMessage
export const responseErrorMessage = createSelector(
    selectCompanies,
    (state: any) => state?.companies.errorMessage
);

//errorMessage
export const getSelectedCompanyId = createSelector(
    selectCompanies,
    (state: any) => state?.companies.selectedCompanyId
);

export const getSelectedCompany = createSelector(
    selectCompanies,
    (state: any) => state?.companies.selectedCompany
);


export const getSelectedPartnerId = createSelector(
    selectCompanies,
    (state: any) => state?.companies.partner_item
);