import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@modules/auth/services";
import { SBRouteData, SideNavItem } from "@modules/navigation/models";

@Component({
  selector: "app-side-nav-item",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./side-nav-item.component.html",
  styleUrls: ["side-nav-item.component.scss"],
})
export class SideNavItemComponent implements OnInit {
  @Input() sideNavItem!: SideNavItem;
  @Input() isActive!: boolean;
  getUrl: string = this.router.url;
  isGuest = !this.authService.isAuthenticated();

  routeData!: SBRouteData;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    if (this.sideNavItem?.link) {
      this.sideNavItem.action = this.getUrl == this.sideNavItem.link;
    }

    if (this.sideNavItem?.text == "Dashboard" && this.isGuest) {
      this.sideNavItem.action = true;
    }
  }

  toggleExpanded(txt: string) {
    if (this.sideNavItem.text == txt) {
      this.sideNavItem.expanded = !this.sideNavItem.expanded;
    }
  }
}
