import { Component, OnInit } from "@angular/core";
import { ModalService } from "@common/components/_modal";
import { Device } from "@modules/devices/models/device";

@Component({
  selector: "app-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.scss"],
})
export class DevicesComponent implements OnInit {
  private modalService: ModalService;
  responseError: any = null;

  deviceIdFocus!: any;

  focusDevice: Device = {
    cd:"",
    id: 0,
    name: "",
    uniqueId: "",
    status: "",
    disabled: false,
    lastUpdate: "",
    positionId: 0,
    groupId: 0,
    phone: "",
    model: "",
    contact: "",
    category: "",
    geofenceIds: [0, 0],
    attributes: {},
  };

  constructor(
    modalService: ModalService,
  ) {
    this.modalService = modalService;
  }

  ngOnInit(): void {
    
  }


  openModalDevice(
    id: string,
    device: Device = {
      cd:"",
      id: 0,
      name: "",
      uniqueId: "",
      status: "",
      disabled: false,
      lastUpdate: "",
      positionId: 0,
      groupId: 0,
      phone: "",
      model: "",
      contact: "",
      category: "",
      geofenceIds: [0, 0],
      attributes: {},
    }
  ) {
    this.modalService.open(id);
    this.responseError = null;
    this.focusDevice = device;
  }
}
