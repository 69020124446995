import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Contact } from "../../models/contact";
import config from "@common/shared/config/config";
import { AuthService } from "@modules/auth/services";

@Injectable({
  providedIn: "root", 
})
export class ApiService {
  private BACKEND_URL = config.BACKEND_URL;
  private PARTNER_URL = `${this.BACKEND_URL}/res-partner`;
  private MODEL_TO_URL_MAP: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.MODEL_TO_URL_MAP = {
      [`${Contact.name}`]: `${this.PARTNER_URL}`,
    };
  }

  //#region DEPARTMENT
  public getAllContactCompany(): Observable<Contact[]> {
    return new Observable<Contact[]>((subscriber) => {
      this.http.get(`${this.PARTNER_URL}`).subscribe(
        (departments: any) => {
          subscriber.next(departments as Contact[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param ContactCompany
   */
  public addContactCompany(ContactCompany: Contact): Observable<any> {
    return new Observable<Contact>((subscriber) => {
      this.http.post(`${this.PARTNER_URL}`, ContactCompany).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param ContactCompany
   */
  public updateContactCompany(ContactCompany: Contact): Observable<any> {
    return new Observable<Contact>((subscriber) => {
      this.http.put(`${this.PARTNER_URL}/${this.authService.getUserInfo().id}`, ContactCompany).subscribe(
        (newInfo: any) => {
          subscriber.next(newInfo);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
  //#endregion

  public deleteEntity(id: number, className: string): Observable<any> {
    let url: string = this.MODEL_TO_URL_MAP[className];

    if (!url || url.length === 0) return {} as Observable<any>;

    return new Observable<any>((subscriber) => {
      this.http.delete(`${url}/${id}`).subscribe(
        () => {
          subscriber.next("ok");
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
