import { Injectable } from '@angular/core';
import { ApiService } from './device-api.service';
import { Observable } from 'rxjs';
import { Device } from '../models/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private apiService: ApiService) {}

  loadAllDevices(): Observable<Device[]> {
    return this.apiService.getAllDevices();
  }

  addDevice(device:any): Observable<Device> {
    return this.apiService.addDevice(device);
  }

  updateDevice(device: Device): Observable<Device> {
    return this.apiService.updateDevice(device);
  }

  deleteDevice(id: number): Observable<any> {
    return this.apiService.deleteEntity(id, Device.name);
  }
}
