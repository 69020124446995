import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { LocalSettingsService } from "@common/services/LocalSettings.service";
import { AuthService } from "@modules/auth/services";
import { NavigationService } from "@modules/navigation/services";
import { CompanyService } from "@modules/organization/services";
import { selectAllCompanies } from "@modules/organization/store/company/company.selector";
import { CompaniesState } from "@modules/organization/store/company/company.state";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-top-nav",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./top-nav.component.html",
  styleUrls: ["../../../../app/app.component.scss"],
})
export class TopNavComponent implements OnInit {
  languages = ["Japanese", "English"];
  storedLang: string =
    this._localSettings.getLanguage() == "en" ? "English" : "Japanese";

  $master_Companies = this.storeCompany.pipe(select(selectAllCompanies));
  master_Companies: Array<any> = [];

  isGuest = !this.authService.isAuthenticated();

  @Input() id: any;
  @Output()topEmitMasterCompanies = new EventEmitter<any>();

  selected_language: string = "English";
  isView: boolean = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService,
    public translate: TranslateService,
    public _localSettings: LocalSettingsService,
    private storeCompany: Store<CompaniesState>,
    public companyService: CompanyService,
    private authService: AuthService
  ) {
    var storedLang: string = _localSettings.getLanguage();
    if (storedLang !== "") {
      translate.use(storedLang);
    }
  }
  ngOnInit() {
  }

  toggleSideNav() {
    this.navigationService.toggleSideNav();
  }

  receiveMasterCompanies($event: any) {
    this.master_Companies = $event;
    this.isView = false;


    this.changeDetectorRef.detectChanges();

    this.topEmitMasterCompanies.emit(this.master_Companies);
  }

  selected_company_id: number = -1;
  receiveSelectedCompanyId($event: any) {
    this.selected_company_id = $event;
  }
}
