import { Injectable } from "@angular/core";
import { SystemUser } from "@modules/auth/models/system-user";
import { Features } from "../models/features.enum";
import { Permission } from "../models/permissions.enum";

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  checkPermission(user: SystemUser): boolean 
  {
    if (!user?.permission?.includes('10') && !user?.permission?.includes('20')) {
      return false;
    }
    return true;
  }

}
