import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouteDetail } from '../models/route-detail';
import { RouteDetailsStates } from './route-detail.reducers';

export const routeDetailsKey = 'routeDetails';
export const selectRouteDetails = createFeatureSelector<RouteDetailsStates>(routeDetailsKey);

export const selectAllRouteDetails = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails.allRouteDetails
);

export const selectAllTimetables = createSelector(
    selectRouteDetails,
    (state: any) => state.routeDetails.timetables
);

export const selectAllAppTimetableHeaders = createSelector(
    selectRouteDetails,
    (state: any) => state.routeDetails.TimetableHeaders
);

export const selectTimetables = createSelector(
    selectRouteDetails,
    (state: any) => state.routeDetails.appTimetables
);

export const selectAllRoutesDisplay = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails?.displayedRoutes
);

export const selectStationARoutesDisplay = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails
);

export const selectARouteWithTwoStations = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails?.aRouteWithTwoStations
);

export const getSelectedRouteDetail = createSelector(
    selectRouteDetails,
    (state: RouteDetailsStates) => state.selectedRouteDetail
  );

  export const isUpdateDurationSuccess = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails?.isUpdateDurationSuccess
);
  export const isAddMultipleRouteDetailsSuccess = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails?.isAddMultipleRouteDetailsSuccess
);
  export const isGetStationHeadersSuccess = createSelector(
    selectRouteDetails,
    (state: any) => state?.routeDetails?.isGetStationHeadersSuccess
);

  export const getRouteDetailsByRouteId = createSelector(
    selectRouteDetails,
    (state: RouteDetailsStates, { route_id} : {route_id:any}) =>
        state.allRouteDetails.filter((route: RouteDetail) => route.route_id === route_id)
);