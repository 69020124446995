import { Injectable } from '@angular/core';
import { ApiService } from './department-api.service';
import { Observable } from 'rxjs';
import { Department } from '../../models/department';

@Injectable({
    providedIn: 'root',
})
export class DepartmentService {
    constructor(private apiService: ApiService) { }

    public loadAllDepartments(): Observable<Department[]> {
        return this.apiService.getAllDepartment();
    }

    public addDepartment(department: Department): Observable<any> {
        return this.apiService.addDepartment(department);
    }

    public updateDepartment(department: Department): Observable<any> {
        return this.apiService.updateDepartment(department);
    }

    public deleteDepartment(id: number): Observable<any> {
        return this.apiService.deleteEntity(id, Department.name);
    }
}
