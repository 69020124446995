import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['../../../../app/app.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  @Input() focused: any =  null;
  @Input() title: string = '';
  @Input() msgConfirm: string = '';
  @Input() showBtnConfirm: boolean = true;

  @Output() btnAccept = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) { 

  }

  ngOnInit(): void {
    
  }

  closeModal() {
    this.activeModal.close();
  }

  acceptFunc() {
    this.btnAccept.emit(this.focused);
    this.closeModal()
  }

}
