import { Injectable } from '@angular/core';
import { ApiService } from './contact-company-api.service';
import { Observable } from 'rxjs';
import { Contact } from '../../models/contact';
import { Partner } from '../../models/partner';

@Injectable({
    providedIn: 'root',
})
export class ContactCompanyService {
    constructor(private apiService: ApiService) { }

    public loadAllContactsCompany(): Observable<Contact[]> {
        return this.apiService.getAllContactCompany();
    }

    public addContactCompany(contact_company: Contact): Observable<any> {
        return this.apiService.addContactCompany(contact_company);
    }

    public updateContactCompany(contact_company: Contact): Observable<any> {
        return this.apiService.updateContactCompany(contact_company);
    }

    //remove partner
    public deletePartner(id: number): Observable<any> {
        return this.apiService.deleteEntity(id, Contact.name);
    }
}
