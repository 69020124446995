export class TrackingState {
  accuracy: number;
  address: string;
  altitude: number;
  batteryLevel: number;
  distance: number;
  ignition: boolean;
  ip: string;
  motion: boolean;
  totalDistance: number;
  course: number;
  deviceId: number;
  deviceTime: string;
  fixTime: string;
  id: number;
  latitude: number;
  longitude: number;
  network: string | null;
  outdated: boolean;
  protocol: string;
  serverTime: string;
  speed: number;
  type: string | null;
  valid: boolean;

  constructor(stateInfo?: any) {
    this.accuracy = stateInfo.accuracy || null;
    this.address = stateInfo.address || null;
    this.altitude = stateInfo.altitude || null;
    this.batteryLevel = stateInfo.batteryLevel || null;
    this.distance = stateInfo.distance || null;
    this.ignition = stateInfo.ignition || null;
    this.ip = stateInfo.ip || null;
    this.motion = stateInfo.motion || null;
    this.totalDistance = stateInfo.totalDistance || null;
    this.course = stateInfo.course || null;
    this.deviceId = stateInfo.deviceId || null;
    this.deviceTime = stateInfo.deviceTime || null;
    this.fixTime = stateInfo.fixTime || null;
    this.id = stateInfo.id || null;
    this.latitude = stateInfo.latitude || null;
    this.longitude = stateInfo.longitude || null;
    this.network = stateInfo.network || null;
    this.outdated = stateInfo.outdated || null;
    this.protocol = stateInfo.protocol || null;
    this.serverTime = stateInfo.serverTime || null;
    this.speed = stateInfo.speed || null;
    this.type = stateInfo.type || null;
    this.valid = stateInfo.valid || null;

    const { attributes } = stateInfo;
    if (attributes) {
      this.batteryLevel = attributes.batteryLevel || null;
      this.distance = attributes.distance || null;
      this.ignition = attributes.ignition || null;
      this.ip = attributes.ip || null;
      this.motion = attributes.motion || null;
      this.totalDistance = attributes.totalDistance || null;
    }
  }
}
