export class Company {
  id: number;
  name: string;
  currency_id: number;
  logo_web: string;
  email: string;
  phone: string;
  company_registry: string;
  account_tax_fiscal_country_id: number;
  account_sale_tax_id: number;
  constructor(info: any = {}) {
    const {
      id,
      name,
      currency_id,
      logo_web,
      email,
      phone,
      company_registry,
      account_tax_fiscal_country_id,
      account_sale_tax_id
    } = info;
    this.id = id;
    this.name = name;
    this.currency_id = currency_id;
    this.logo_web = logo_web;
    this.email = email;
    this.phone = phone;
    this.company_registry = company_registry;
    this.account_tax_fiscal_country_id = account_tax_fiscal_country_id;
    this.account_sale_tax_id = account_sale_tax_id;
  }
}
