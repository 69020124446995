import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Company } from "../../models/company";
import config from "@common/shared/config/config";
import { AuthService } from "@modules/auth/services";
import { Currency } from "@modules/organization/models/Currency";
import { Country } from "@modules/organization/models/Country";
import { CountryState } from "@modules/organization/models/CountryState";
import { Partner } from "@modules/organization/models/Partner";

@Injectable({
  providedIn: "root", 
})
export class ApiService {
  private BACKEND_URL = config.BACKEND_URL;
  private COMPANY_URL = `${this.BACKEND_URL}/res-company`;
  private CURRENCY_URL = `${this.BACKEND_URL}/res-currency`;
  private COUNTRY_STATE_URL = `${this.BACKEND_URL}/res-country-state`;
  private COUNTRY_URL = `${this.BACKEND_URL}/res-country`;
  private PARTNER_URL = `${this.BACKEND_URL}/res-partner`;
  
  private MODEL_TO_URL_MAP: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.MODEL_TO_URL_MAP = {
      [`${Company.name}`]: `${this.COMPANY_URL}`,
    };
  }

  //#region Company
  public getAllCompany(): Observable<Company[]> {
    return new Observable<Company[]>((subscriber) => {
      this.http.get(`${this.COMPANY_URL}`).subscribe(
        (companies: any) => {
          subscriber.next(companies as Company[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  //#region Currency
  public getAllCurency(): Observable<Currency[]> {
    return new Observable<Currency[]>((subscriber) => {
      this.http.get(`${this.CURRENCY_URL}`).subscribe(
        (currency: any) => {
          subscriber.next(currency as Currency[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  //#region Country 
  public getAllCountry(): Observable<Country[]> {
    return new Observable<Country[]>((subscriber) => {
      this.http.get(`${this.COUNTRY_URL}`).subscribe(
        (country: any) => {
          subscriber.next(country as Country[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  //#region Country State
  public getCountryState(): Observable<CountryState[]> {
    return new Observable<CountryState[]>((subscriber) => {
      this.http.get(`${this.COUNTRY_STATE_URL}`).subscribe(
        (countryState: any) => {
          subscriber.next(countryState as CountryState[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param Company
   */
  public addCompany(Company: Company): Observable<any> {
    return new Observable<Company>((subscriber) => {
      this.http.post(`${this.COMPANY_URL}`, Company).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   *
   * @param Company
   */
  public updateCompany(Company: Company): Observable<any> {
    return new Observable<Company>((subscriber) => {
      this.http.put(`${this.COMPANY_URL}/${this.authService.getUserInfo().id}`, Company).subscribe(
        (newInfo: any) => {
          subscriber.next(newInfo);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
  //#endregion

 /**
   *
   * @param partner
   */
 public addPartner(partner: Partner): Observable<any> {
  return new Observable<Partner>((subscriber) => {
    this.http.post(`${this.PARTNER_URL}`, partner).subscribe(
      (info: any) => {
        subscriber.next(info);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
      }
    );
  });
}

  /**
   *
   * @param partner
   */
  public updatePartner(partner: Partner): Observable<any> {
    return new Observable<Partner>((subscriber) => {
      this.http.put(`${this.PARTNER_URL}/${this.authService.getUserInfo().id}`, partner).subscribe(
        (newInfo: any) => {
          subscriber.next(newInfo);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public deleteEntity(id: number, className: string): Observable<any> {
    let url: string = this.MODEL_TO_URL_MAP[className];

    if (!url || url.length === 0) return {} as Observable<any>;

    return new Observable<any>((subscriber) => {
      this.http.delete(`${url}/${id}`).subscribe(
        () => {
          subscriber.next("ok");
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
