import {Injectable} from "@angular/core";

@Injectable()
export class LocalSettingsService{
    static getLanguage: any;
    static getCompanyId: number;
    //todo: better error handling!
    getLanguage():string{
        if (localStorage){
            LocalSettingsService.getLanguage = localStorage['language'] || ""
            return localStorage['language'] || "";
        }
        else{
            LocalSettingsService.getLanguage = "";
            return "";
        }
    }

    setLanguage(language: string){
        if (localStorage){
            localStorage['language'] = language;
        }
    }

    setCompanyId(companyId: number){
        if (localStorage){
            localStorage['companyId'] = companyId;
        }
    }

    getCompanyId():number{
        if (localStorage){
            LocalSettingsService.getCompanyId = localStorage['companyId'] || -1
            return localStorage['companyId'] || -1;
        }
        else{
            LocalSettingsService.getCompanyId = -1;
            return -1;
        }
    }
}