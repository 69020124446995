import { createAction, props } from '@ngrx/store';

export enum EventActionTypes {
  UPDATE_EVENT_FROM_SOCKET = '[Events] Update events from socket',
}

export const updateEventFromSocket = createAction(
  EventActionTypes.UPDATE_EVENT_FROM_SOCKET,
  props<{ events: Event[] }>()
);
