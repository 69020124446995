import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import config from '@common/shared/config/config';
import { SystemUser } from '@modules/auth/models/system-user';
import { Partner } from '@modules/organization/models/Partner';
import { AuthService } from '@modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private BACKEND_URL = config.BACKEND_URL;
  private USER_URL = `${this.BACKEND_URL}/app-users`;
  private ROLE_URL = `${this.BACKEND_URL}/app-roles`;
  private RES_USER_URL = `${this.BACKEND_URL}/res-user`;
  private MODEL_TO_URL_MAP: any;
  

  constructor(private http: HttpClient, private authService: AuthService) {
    this.MODEL_TO_URL_MAP = {
      [`${User.name}`]: `${this.USER_URL}`,
      [`res-user`]: `${this.RES_USER_URL}`,
    };
   }

   public getAllRoles(): Observable<any[]> {
    return new Observable<any[]>((subscriber) => {
      this.http.get(`${this.ROLE_URL}`).subscribe(
        (roles) => {
          subscriber.next(roles as any[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  //#region APP_USER
  public getAllUsers(): Observable<User[]> {
    return new Observable<User[]>((subscriber) => {
      this.http.get(`${this.USER_URL}`).subscribe(
        (users: any) => {
          subscriber.next(users as User[]);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public addUser(user: User): Observable<User> {
    return new Observable<User>((subscriber) => {
      this.http.post(this.USER_URL, user).subscribe(
        (info: any) => {
          subscriber.next(new User(info));
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public updateUser(user: User): Observable<User> {
    return new Observable<User>((subscriber) => {
      this.http
        .put(`${this.USER_URL}/${user.id}`, user)
        .subscribe(
          (newInfo: any) => {
            subscriber.next(new User(newInfo));
            subscriber.complete();
          },
          (error) => {
            subscriber.error(error);
          }
        );
    });
  }
  //#endregion

  ///app-users/confirm-password/c14c76c6df8b9ffb758a80dbf5ac438c5836dbe7dbdc9970
  public getConfirmPassword(token: string): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.http.get(`${this.USER_URL}/confirm-password/${token}`).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  //update-password
  public updatePassword(user: any): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.http.put(`${this.USER_URL}/forgot-password`, user).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public addResUser(resUser: any): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.http.post(this.RES_USER_URL, resUser).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public updateResUser(resUser: any): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.http.put(this.RES_USER_URL, resUser).subscribe(
        (info: any) => {
          subscriber.next(info);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  public deleteEntity(id: number, className: string): Observable<any> {
    let url: string = this.MODEL_TO_URL_MAP[className]

    if (!url || url.length === 0) return {} as Observable<any>;

    return new Observable<any>((subscriber) => {
      this.http.delete(`${url}/${id}`).subscribe(
        () => {
          subscriber.next('ok');
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
