import { TrackingState } from '@modules/devices/models/tracking-state';
import { createAction, props } from '@ngrx/store';

export enum TrackingStateActionTypes {
  UPDATE_TRACKING_STATES = '[Tracking State] Update Tracking States',
  ADD_POSITION_TO_DISPLAY_LIST = '[Tracking State] Add Position To Display List',
  REMOVE_POSITION_FROM_DISPLAY_LIST = '[Tracking State] Remove Position From Display List',
}

export const UpdateTrackingStates = createAction(
  TrackingStateActionTypes.UPDATE_TRACKING_STATES,
  props<{ trackingStates: TrackingState[] }>()
);

export const AddPositionToDisplayList = createAction(
  TrackingStateActionTypes.ADD_POSITION_TO_DISPLAY_LIST,
  props<{ deviceId: number }>()
);

export const RemovePositionFromDisplayList = createAction(
  TrackingStateActionTypes.REMOVE_POSITION_FROM_DISPLAY_LIST,
  props<{ deviceId: number }>()
);

export const AddInfoMoveList = createAction(
  TrackingStateActionTypes.ADD_POSITION_TO_DISPLAY_LIST,
  props<{ InfoMove: any }>()
);

export const RemoveInfoMoveList = createAction(
  TrackingStateActionTypes.REMOVE_POSITION_FROM_DISPLAY_LIST,
  props<{ routeId: number }>()
);
