//  const origin = 'https://nowodau-api.blockhive.vn';
// const origin = 'http://localhost:8082';

//major
const origin = 'https://nowodau-api.blockhive.vn';
const origin_fe = 'https://nowodau.blockhive.vn';

//ip nat inside source static tcp
// const origin = 'http://113.161.54.30:22082';
// const origin_fe = 'http://113.161.54.30:22080';

const url = origin.split("://")[1];
const protocol = origin.split("://")[0];
const socketProtocol = protocol === 'http' ? 'ws' : 'wss';

const config = {
    FRONTEND_URL: `${origin_fe}/`,
    BACKEND_URL: `${protocol}://${url}/api`,
    BACKEND_WS_URL: `${socketProtocol}://${url}/api/socket`
}

export default config;