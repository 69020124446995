/* tslint:disable: ordered-imports*/
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";

/* Modules */
import { AppCommonModule } from "@common/app-common.module";

/* Components */
import * as navigationComponents from "./components";

/* Containers */
import * as navigationContainers from "./containers";

/* Layouts */
import * as appCommonLayouts from "./layouts";

/* Guards */
import * as navigationGuards from "./guards";

/* Services */
import * as navigationServices from "./services";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "@common/components/_modal";
import { PermissionModule } from "@modules/permission/permission.module";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MatSelectModule } from "@angular/material/select";
import { StoreModule } from "@ngrx/store";
import { reducers } from "@modules/organization/store/company/company.state";
import { CompanyEffects } from "@modules/organization/store/company/company.effects";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
    providers: [...navigationServices.services, ...navigationGuards.guards],
    declarations: [
        ...navigationContainers.containers,
        ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    exports: [
        ...navigationContainers.containers,
        ...navigationComponents.components,
        ...appCommonLayouts.layouts,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AppCommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        PermissionModule,

        //mat-select
        MatSelectModule,

        StoreModule.forFeature("companies", reducers),
        EffectsModule.forFeature([CompanyEffects]),

        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
        
        ]
})
export class NavigationModule {}
