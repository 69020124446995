import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { FileUploadService } from "@common/services/image-upload/file-upload.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.css"],
})
export class ImageUploadComponent implements OnInit {
  @Input() id: any;
  @Input() isView: boolean = false;
  @Input() path_img: any;
  @Output() logo_web = new EventEmitter<any>();
  imageUrl: any = "https://i.ibb.co/fDWsn3G/buck.jpg";
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = "";

  //logo.png in assets
  preview: any;
  fileBytes: any;

  imageInfos?: Observable<any>;

  //#logo
  @ViewChild("fileInput") fileInput!: ElementRef;

  constructor(
    private uploadService: FileUploadService,
    private domSanitizer: DomSanitizer
  ) {
    //change text html fileInput
  }

  ngOnInit(): void {
    //change text html fileInput

    this.imageInfos = this.uploadService.getFiles();
    if (this.path_img) {
      this.preview = this.domSanitizer.bypassSecurityTrustUrl(
        "data:image/jpg;base64," + this.path_img
      );
    } else this.preview = "../../../../assets/logo/logo.png";
  }

  ngAfterViewInit() {
    //change text html fileInput
    if (this.fileInput) {
      this.fileInput.nativeElement.innerHTML = "Upload Image";
      this.fileInput.nativeElement.style.color = "white";
      this.fileInput.nativeElement.style.backgroundColor = "#007bff";
      this.fileInput.nativeElement.style.border = "1px solid #007bff";
      this.fileInput.nativeElement.style.borderRadius = "5px";
      this.fileInput.nativeElement.style.padding = "5px";
      this.fileInput.nativeElement.style.cursor = "pointer";
      //change text place holder

      this.fileInput.nativeElement.style.textAlign = "center";
      this.fileInput.nativeElement.placeholder = "Upload Image";
      this.fileInput.nativeElement.style.visibility = "hidden";
    }
  }

  selectFile(event: any): void {
    this.message = "";
    this.preview = "";
    this.fileBytes = "";
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = "";
        this.currentFile = file;
        this.fileBytes = "";
        this.logo_web.emit(file);

        const reader = new FileReader();
        reader.readAsDataURL(this.currentFile);
        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        const reader1 = new FileReader();
        let array: any;
        reader1.readAsArrayBuffer(file);
        reader1.onload = (e: any) => {
          var arrayBuffer = reader1.result;
          if (arrayBuffer instanceof ArrayBuffer) {
            array = new Uint8Array(arrayBuffer);
            this.fileBytes = array;
          }
        };
      }
    }
  }

  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;
        this.logo_web.emit(file);

        this.uploadService.upload(this.fileBytes, this.id).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.imageInfos = this.uploadService.getFiles();
              this.imageUrl = this.imageInfos;
            }
          },
          error: (err: any) => {
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = "Could not upload the image!";
            }

            this.currentFile = undefined;
          },
        });
      }

      this.selectedFiles = undefined;
    }
  }

  //copy file upload into assets folder
  copyFileToAssets(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        //copy file to assets folder
      }

      this.selectedFiles = undefined;
    }
  }
}
