import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AuthService } from "@modules/auth/services/auth.service";
import {
  NavigationService,
  SideNavService,
} from "@modules/navigation/services";
import { Features } from "@modules/permission/models/features.enum";
import { Permission } from "@modules/permission/models/permissions.enum";
import { Subscription } from "rxjs";

@Component({
  selector: "app-side-nav",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./side-nav.component.html",
  styleUrls: ["side-nav.component.scss"],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() getUrl!: string;
  @Input() sidenavStyle!: string;

  @Input() sideNavItems!: any;
  @Input() sideNavSections!: any[];

  subscription: Subscription = new Subscription();
  routeDataSubscription!: Subscription;

  features = Features;
  permission = Permission;

  constructor(
    public navigationService: NavigationService,
    public authService: AuthService,
    private sideNavService: SideNavService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
