import { createAction, props } from '@ngrx/store';
import { Company } from '../../models/company';

export enum CompanyActionTypes {
    LOAD_ALL_COMPANIES = '[Companies] Load All Companies',
    LOAD_ALL_COMPANIES_SUCCESS = '[Companies] Load All Success',
    LOAD_ALL_COMPANIES_FAILURE = '[Companies] Load All Failure',

    ADD_COMPANY = '[Companies] Add Company',
    ADD_COMPANY_SUCCESS = '[Companies] Add Company Success',
    ADD_COMPANY_FAILURE = '[Companies] Add Company Failure',

    UPDATE_COMPANY = '[Companies] Update Company',
    UPDATE_COMPANY_SUCCESS = '[Companies] Update Company Success',
    UPDATE_COMPANY_FAILURE = '[Companies] Update Company Failure',

    LOAD_ALL_PARTNERS = '[Partner] Load All Partner',
    LOAD_ALL_PARTNERS_SUCCESS = '[Partner] Load All Success',
    LOAD_ALL_PARTNERS_FAILURE = '[Partner] Load All Failure',

    ADD_PARTNER = '[Partner] Add Partner',
    ADD_PARTNER_SUCCESS = '[Partner] Add Partner Success',
    ADD_PARTNER_FAILURE = '[Partner] Add Partner Failure',

    UPDATE_PARTNER = '[Partner] Update Partner',
    UPDATE_PARTNER_SUCCESS = '[Partner] Update Partner Success',
    UPDATE_PARTNER_FAILURE = '[Partner] Update Partner Failure',

    //remove Partner
    REMOVE_PARTNER = '[Partner] Remove Partner',
    REMOVE_PARTNER_SUCCESS = '[Partner] Remove Partner Success',
    REMOVE_PARTNER_FAILURE = '[Partner] Remove Partner Failure',

    REMOVE_COMPANY = '[Companies] Remove Company',
    REMOVE_COMPANY_SUCCESS = '[Companies] Remove Company Success',
    REMOVE_COMPANY_FAILURE = '[Companies] Remove Company Failure',

    RESET_ERROR = '[Companies] Reset Error',
    SELECTED_COMPANY = '[Companies] Selected Company',

    LOAD_ALL_CURRENCY = '[Currency] Load All Currency',
    LOAD_ALL_CURRENCY_SUCCESS = '[Currency] Load All Success',
    LOAD_ALL_CURRENCY_FAILURE = '[Currency] Load All Failure',

    LOAD_ALL_COUNTRY = '[Country] Load All Country',
    LOAD_ALL_COUNTRY_SUCCESS = '[Country] Load All Success',
    LOAD_ALL_COUNTRY_FAILURE = '[Country] Load All Failure',

    LOAD_ALL_COUNTRY_STATE = '[CountryState] Load All CountryState',
    LOAD_ALL_COUNTRY_STATE_SUCCESS = '[CountryState] Load All Success',
    LOAD_ALL_COUNTRY_STATE_FAILURE = '[CountryState] Load All Failure',
}

export const LoadAllCurrency = createAction(CompanyActionTypes.LOAD_ALL_CURRENCY);

export const LoadAllCurrencySuccess = createAction(
    CompanyActionTypes.LOAD_ALL_CURRENCY_SUCCESS,
    props<{ currency: any[] }>()
);

export const LoadAllCurrencyFailure = createAction(
    CompanyActionTypes.LOAD_ALL_CURRENCY_FAILURE,
    props<{ error: string }>()
);

export const LoadAllCountry = createAction(CompanyActionTypes.LOAD_ALL_COUNTRY);

export const LoadAllCountrySuccess = createAction(
    CompanyActionTypes.LOAD_ALL_COUNTRY_SUCCESS,
    props<{ country: any[] }>()
);

export const LoadAllCountryFailure = createAction(
    CompanyActionTypes.LOAD_ALL_COUNTRY_FAILURE,
    props<{ error: string }>()
);

export const LoadAllCountryState = createAction(CompanyActionTypes.LOAD_ALL_COUNTRY_STATE);

export const LoadAllCountryStateSuccess = createAction(
    CompanyActionTypes.LOAD_ALL_COUNTRY_STATE_SUCCESS,
    props<{ countryState: any[] }>()
);

export const LoadAllCountryStateFailure = createAction(
    CompanyActionTypes.LOAD_ALL_COUNTRY_STATE_FAILURE,
    props<{ error: string }>()
);
export const LoadAllCompanies = createAction(CompanyActionTypes.LOAD_ALL_COMPANIES);

export const LoadAllCompaniesSuccess = createAction(
    CompanyActionTypes.LOAD_ALL_COMPANIES_SUCCESS,
    props<{ companies: any[] }>()
);

export const LoadAllCompaniesFailure = createAction(
    CompanyActionTypes.LOAD_ALL_COMPANIES_FAILURE,
    props<{ error: string }>()
);

export const LoadAllPartners = createAction(CompanyActionTypes.LOAD_ALL_PARTNERS);

export const LoadAllPartnersSuccess = createAction(
    CompanyActionTypes.LOAD_ALL_PARTNERS_SUCCESS,
    props<{ partners: any[] }>()
);

export const LoadAllPartnersFailure = createAction(
    CompanyActionTypes.LOAD_ALL_PARTNERS_FAILURE,
    props<{ error: string }>()
);

export const addPartner = createAction(
    CompanyActionTypes.ADD_PARTNER,
    props<{ partner: any }>()
);

export const AddPartnerSuccess = createAction(
    CompanyActionTypes.ADD_PARTNER_SUCCESS,
    props<{ partner: any }>()
);

export const AddPartnerFailure = createAction(
    CompanyActionTypes.ADD_PARTNER_FAILURE,
    props<{ error: string }>()
);

export const UpdatePartner = createAction(
    CompanyActionTypes.UPDATE_PARTNER,
    props<{ partner: any }>()
);

export const UpdatePartnerSuccess = createAction(
    CompanyActionTypes.UPDATE_PARTNER_SUCCESS,
    props<{ partner: any }>()
);

export const UpdatePartnerFailure = createAction(
    CompanyActionTypes.UPDATE_PARTNER_FAILURE,
    props<{ error: string }>()
);

export const AddCompany = createAction(
    CompanyActionTypes.ADD_COMPANY,
    props<{ company: any }>()
);

export const AddCompanySuccess = createAction(
    CompanyActionTypes.ADD_COMPANY_SUCCESS,
    props<{ company: any }>()
);

export const AddCompanyFailure = createAction(
    CompanyActionTypes.ADD_COMPANY_FAILURE,
    props<{ error: string }>()
);

export const UpdateCompany = createAction(
    CompanyActionTypes.UPDATE_COMPANY,
    props<{ company: any }>()
);

export const UpdateCompanySuccess = createAction(
    CompanyActionTypes.UPDATE_COMPANY_SUCCESS,
    props<{ company: any }>()
);

export const UpdateCompanyFailure = createAction(
    CompanyActionTypes.UPDATE_COMPANY_FAILURE,
    props<{ error: string }>()
);

//remove Partner
export const RemovePartner = createAction(
    CompanyActionTypes.REMOVE_PARTNER,
    props<{ id: number }>()
);

export const RemovePartnerSuccess = createAction(
    CompanyActionTypes.REMOVE_PARTNER_SUCCESS,
    props<{ id: number }>()
);

export const RemovePartnerFailure = createAction(
    CompanyActionTypes.REMOVE_PARTNER_FAILURE,
    props<{ error: string }>()
);

export const RemoveCompany = createAction(
    CompanyActionTypes.REMOVE_COMPANY,
    props<{ id: number }>()
);

export const RemoveCompanySuccess = createAction(
    CompanyActionTypes.REMOVE_COMPANY_SUCCESS,
    props<{ id: number }>()
);

export const RemoveCompanyFailure = createAction(
    CompanyActionTypes.REMOVE_COMPANY_FAILURE,
    props<{ error: string }>()
);

export const ResetError = createAction(
    CompanyActionTypes.RESET_ERROR
);

export const SelectedCompany = createAction(
    CompanyActionTypes.SELECTED_COMPANY,
    props<{ id: number,selectedCompany?:any }>()
);