import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import * as devicesContainers from "./containers";

import * as devicesComponents from "./components";
import * as devicesDirective from "./directives";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCommonModule } from "@common/app-common.module";
import { ModalModule } from "@common/components/_modal";
import { NavigationModule } from "@modules/navigation/navigation.module";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { DeviceEffects } from "./store/device.effects";
import { reducers } from "./store/device.state";
import { NgbModalDraggableModule } from "ngb-modal-draggable";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NgxPaginationModule } from "ngx-pagination";
import { NgbToast } from "@ng-bootstrap/ng-bootstrap";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { UtilityModule } from "@modules/utility/utility.module";

@NgModule({
  declarations: [
    ...devicesContainers.containers,
    ...devicesComponents.components,
    ...devicesDirective.directives,
  ],
  imports: [
    UtilityModule,
    CommonModule,
    //common
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    NavigationModule,
    ModalModule,
    NgbModalDraggableModule,

    //mat-form-field
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,

    //store
    StoreModule.forFeature("tracking", reducers),
    StoreModule.forFeature("events", reducers),
    StoreModule.forFeature("devices", reducers),

    EffectsModule.forFeature([DeviceEffects]),
    // EffectsModule.forRoot([UserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxPaginationModule,
  ],
  exports: [
    ...devicesContainers.containers,
    ...devicesComponents.components,
    ...devicesDirective.directives,
  ]
})
export class DevicesModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
