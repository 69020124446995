import * as users from './reducers/user.reducers';
import { selectAllUsers, selectInfoConfirmPassword } from './selectors/users.selector';

export interface UserState {
  userState: users.UsersState
}

export const reducers = {
    users: users.reducer
};


export const userSelect = [ selectAllUsers,selectInfoConfirmPassword]


