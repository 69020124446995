import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { mergeMap, catchError, map, tap } from "rxjs/operators";
import {
  UserActionTypes,
  LoadAllUsersSuccess,
  LoadAllUsersFailure,
  AddUserSuccess,
  AddUserFailure,
  UpdateUserSuccess,
  UpdateUserFailure,
  DeleteUserSuccess,
  DeleteUserFailure,
  LoadAllRolesSuccess,
  LoadAllRolesFailure,
  AddResUserSuccess,
  UpdateResUserSuccess,
  UpdateResUserFailure,
  DeleteResUserFailure,
  GetConfirmPasswordSuccess,
  GetConfirmPasswordFailure,
  UpdatePasswordSuccess,
  UpdatePasswordFailure,
} from "../actions/user.actions";
import { UserService } from "@modules/users/services/user.service";
import { User } from "@modules/users/models/user";
import { ToastService } from "@common/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ResUser } from "@modules/users/models/ResUser";
import { ContactCompanyService } from "@modules/organization/services/contact-company/contact-company.service";

@Injectable({ providedIn: "root" })
export class UserEffects {
  constructor(
    private actions: Actions,
    private userService: UserService,
    public toastService: ToastService,
    private translateService: TranslateService
  ) {}

  //==============================================
  LoadAllRoles = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.LOAD_ALL_ROLES),
      mergeMap(() => {
        return this.userService.loadAllRoles().pipe(
          map((roles) => {
            return LoadAllRolesSuccess({ roles });
          }),
          catchError((error) => {
            return of(LoadAllRolesFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllRolesFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.LOAD_ALL_ROLES_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args?.error.error);
        })
      );
    },
    { dispatch: false }
  );

  //==============================================
  LoadAllUsers = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.LOAD_ALL_USERS),
      mergeMap(() => {
        return this.userService.loadAllUsers().pipe(
          map((users) => {
            return LoadAllUsersSuccess({ users });
          }),
          catchError((error) => {
            return of(LoadAllUsersFailure({ error }));
          })
        );
      })
    );
  });

  LoadAllUsersFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.LOAD_ALL_USERS_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args?.error.error);
        })
      );
    },
    { dispatch: false }
  );

  //==============================================
  AddUser = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.ADD_USER),
      mergeMap((args: any) => {
        const user: User = args.user;
        return this.userService.addUser(user).pipe(
          map((newUserInfo) => {
            const newUser = new User(newUserInfo);
            return AddUserSuccess({ user: newUser });
          }),
          catchError((error) => {
            return of(AddUserFailure({ error }));
          })
        );
      })
    );
  });

  AddUserFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.ADD_USER_FAILURE),
        tap((args: any) => {
          // this.toastService.showError(args?.error.error);
        })
      );
    },
    { dispatch: false }
  );

  //==============================================

  UpdateUser = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.UPDATE_USER),
      mergeMap((args: any) => {
        return this.userService.updateUser(args.user).pipe(
          map((userInfo) => {
            this.translateService
              .get("users.update_success")
              .subscribe((res: string) => {
                this.toastService.showSuccess(res);
              });
            const updatedUserInfo = { ...userInfo, email: args.user.email };
            const updatedUser = new User(updatedUserInfo);
            return UpdateUserSuccess({ user: updatedUser });
          }),
          catchError((response) => {
            return of(UpdateUserFailure({ error: response.error }));
          })
        );
      })
    );
  });

  UpdateUserFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.UPDATE_USER_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  RemoveUser = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.DELETE_USER),
      mergeMap(({ userId }) => {
        return this.userService.deleteUser(userId).pipe(
          map(() => {
            return DeleteUserSuccess({ userId });
          }),
          catchError((response) => {
            return of(DeleteUserFailure({ error: response.error }));
          })
        );
      })
    );
  });

  DeleteUserFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.DELETE_USER_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  //==============================================
  AddResUser = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.ADD_RES_USER),
      mergeMap((args: any) => {
        const resUser: ResUser = args.resUser;
        return this.userService.addResUser(resUser).pipe(
          map((newResUserInfo) => {
            this.translateService
              .get("users.add_success")
              .subscribe((res: string) => {
                this.toastService.showSuccess(res);
              });
            const newResUser = new ResUser(newResUserInfo);
            return AddResUserSuccess({ resUser: newResUser });
          }),
          catchError((error) => {
            return of(AddUserFailure({ error }));
          })
        );
      })
    );
  });

  AddResUserFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.ADD_RES_USER_FAILURE),
        tap((args: any) => {
          this.toastService.showError(args?.error.error);
        })
      );
    },
    { dispatch: false }
  );

  UpdateResUser = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.UPDATE_RES_USER),
      mergeMap((args: any) => {
        return this.userService.updateResUser(args.resUser).pipe(
          map((newResUser) => {
            return UpdateResUserSuccess({ resUser: newResUser });
          }),
          catchError((response) => {
            return of(UpdateResUserFailure({ error: response.error }));
          })
        );
      })
    );
  });

  UpdateResUserFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.UPDATE_RES_USER_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  RemoveResUser = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.DELETE_RES_USER),
      mergeMap(({ userId }) => {
        return this.userService.deleteResUser(userId).pipe(
          map(() => {
            return DeleteUserSuccess({ userId });
          }),
          catchError((response) => {
            return of(DeleteResUserFailure({ error: response.error }));
          })
        );
      })
    );
  });

  DeleteResUserFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.DELETE_RES_USER_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  //getConfirmPassword
  GetConfirmPassword = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.GET_CONFIRM_PASSWORD),
      mergeMap((args: any) => {
        return this.userService.getConfirmPassword(args.token).pipe(
          map((response) => {
            return GetConfirmPasswordSuccess({ response });
          }),
          catchError((response) => {
            return of(GetConfirmPasswordFailure({ error: response.error }));
          })
        );
      })
    );
  });
  
  GetConfirmPasswordFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.GET_CONFIRM_PASSWORD_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    },
    { dispatch: false }
  );

  //UPDATE_PASSWORD
  UpdatePassword = createEffect(() => {
    return this.actions.pipe(
      ofType(UserActionTypes.UPDATE_PASSWORD),
      mergeMap((args: any) => {
        return this.userService.updatePassword(args.user).pipe(
          map((response) => {
            return UpdatePasswordSuccess({ response });
          }),
          catchError((response) => {
            return of(UpdatePasswordFailure({ error: response.error }));
          })
        );
      })
    );
  });

  UpdatePasswordFailure = createEffect(
    () => {
      return this.actions.pipe(
        ofType(UserActionTypes.UPDATE_PASSWORD_FAILURE),
        tap((errorResponse: any) => {
          this.toastService.showError(errorResponse.error);
        })
      );
    }
    , { dispatch: false }
  );

}
