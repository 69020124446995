import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './reducers/auth.reducers';


export const authKey = 'auth';
export const selectAuth = createFeatureSelector<State>(authKey);

export const selectUserLogin = createSelector(
    selectAuth,
    (userState: any) => userState.auth.user
  );

export const getErrorLogin = createSelector(
    selectAuth,
    (state: any) => state.auth.errorMessage
);