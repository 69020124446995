export class Device {
  cd:string;
  id: number;
  name: string;
  uniqueId: string;
  status?: string;
  disabled?: boolean;
  lastUpdate?: string;
  positionId?: number;
  groupId?: number;
  phone?: string;
  model?: string;
  contact?: string;
  category?: string;
  geofenceIds?: number[];
  attributes?: object;
  companyId?: number;
  companyName?: string;

  constructor(deviceInfo: any = {}) {
    const {
      cd,
      id,
      name,
      uniqueId,
      status,
      disabled,
      lastUpdate,
      positionId,
      groupId,
      phone,
      model,
      contact,
      category,
      geofenceIds,
      attributes
      , companyId
      , companyName
    } = deviceInfo;
    this.cd = cd
    this.id = id;
    this.name = name;
    this.uniqueId = uniqueId;
    this.status = status;
    this.disabled = disabled;
    this.lastUpdate = lastUpdate;
    this.positionId = positionId;
    this.groupId = groupId;
    this.phone = phone;
    this.model = model;
    this.contact = contact;
    this.category = category;
    this.geofenceIds = geofenceIds;
    this.attributes = attributes;
    this.companyId = companyId;
    this.companyName = companyName;
  }
}
