/* tslint:disable: ordered-imports*/
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

/* Third Party */
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { IconsModule } from "@modules/icons/icons.module";

const thirdParty = [IconsModule, NgbModule];

/* Containers */
import * as appCommonContainers from "./containers";

/* Components */
import * as appCommonComponents from "./components";

/* Guards */
import * as appCommonGuards from "./shared/guards";

/* Services */
import * as appCommonServices from "./services";
import * as authServices from "@modules/auth/services";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimeZoneService } from "./shared/services/time-zone.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatInputModule } from "@angular/material/input";
import { NgbModalDraggableModule } from "ngb-modal-draggable";
import { MatGridListModule } from "@angular/material/grid-list";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { FileUploadModule } from "ng2-file-upload";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    NgbModalDraggableModule,
    MatGridListModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    ...thirdParty,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    HttpClientModule, 
    FileUploadModule
  ],
  providers: [
    ...appCommonServices.services,
    ...authServices.services,
    ...appCommonGuards.guards,
    { provide: "TimeZoneService", useClass: TimeZoneService },
  ],
  declarations: [
    ...appCommonContainers.containers,
    ...appCommonComponents.components,
  ],
  exports: [
    ...appCommonContainers.containers,
    ...appCommonComponents.components,
    ...thirdParty,
  ],
})
export class AppCommonModule {}
