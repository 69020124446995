/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faBars,
  faBookOpen,
  faCamera,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faColumns,
  faMap,
  faSearch,
  faTable,
  faTachometerAlt,
  faUser,
  faComment,
  faArrowDown,
  faArrowRight,
  faChevronRight,
  faTrash,
  faEdit,
  faCog,
  faPlus,
  faPaperPlane,
  faTaxi,
  faTimes,
  faSave,
  faCheck,
  faScrewdriver,
  faMicrochip,
  faCalendar,
  faFlag,
  faInfoCircle,
  faCrosshairs,
  faChevronLeft,
  faExclamationTriangle,
  faRoute,
  faCalendarCheck,
  faEraser,
  faList,
  faDrawPolygon,
  faArrowCircleLeft,
  faArrowCircleRight,
  faCalendarAlt,
  faSign,
  faMapMarkerAlt,
  faClock,
  faCar,
  faWalking,
  faBicycle,
  faPlay,
  faPause,
  faExpand,
  faCompress,
  faWrench,
  faSitemap,
  faEllipsisV,
  faBuilding,
  faSignInAlt,
  //translate icon
  faLanguage
} from "@fortawesome/free-solid-svg-icons";

export const fontAwesomeSolidIcons = {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faBars,
  faBookOpen,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faColumns,
  faSearch,
  faTable,
  faTachometerAlt,
  faUser,
  faMap,
  faCamera,
  faComment,
  faArrowDown,
  faArrowRight,
  faChevronRight,
  faTrash,
  faEdit,
  faCog,
  faPlus,
  faPaperPlane,
  faTaxi,
  faTimes,
  faSave,
  faCheck,
  faScrewdriver,
  faMicrochip,
  faCalendar,
  faFlag,
  faInfoCircle,
  faCrosshairs,
  faChevronLeft,
  faExclamationTriangle,
  faRoute,
  faCalendarCheck,
  faEraser,
  faList,
  faDrawPolygon,
  faArrowCircleLeft,
  faArrowCircleRight,
  faCalendarAlt,
  faSign,
  faMapMarkerAlt,
  faClock,
  faCar,
  faWalking,
  faBicycle,
  faPlay,
  faPause,
  faExpand,
  faCompress,
  faWrench,
  faSitemap,
  faEllipsisV,
  faBuilding,
  faSignInAlt,
  faLanguage
};
