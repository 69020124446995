import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  filterByKey,
  filterMultipleKeys,
  getLimit1SortDescByColumn,
  isNullOrEmpty,
  sort,
} from "@common/shared/utils/func.utils";
import { SortEvent } from "@modules/devices/directives/sortable.directive";
import { Device } from "@modules/devices/models/device";
import { DevicesState } from "@modules/devices/store/device.state";
import {
  AddPositionToDisplayList,
  RemovePositionFromDisplayList,
} from "@modules/devices/store/tracking-state.actions";
import { select, Store } from "@ngrx/store";
import { getDisplayedPositionsList } from "@modules/devices/store/tracking-states.selector";
import {
  LoadAllDevices,
  SelectDevice,
  ToggleDeviceName,
} from "@modules/devices/store/device.actions";
import { selectAllDevices } from "@modules/devices/store/devices.selector";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalAddDevicesComponent } from "../modal-add-devices/modal-add-devices.component";
import { ModalUpdateDevicesComponent } from "../modal-update-devices/modal-update-devices.component";
import { ModalDeleteDevicesComponent } from "../modal-delete-devices/modal-delete-devices.component";
import { AuthService } from "@modules/auth/services";
import { PermissionService } from "@modules/permission/services/permission.service";
import { selectAllSchedules } from "@modules/schedules/store/schedules.selector";
import { SchedulesState } from "@modules/schedules/store/Schedules.state";
import { LoadAllSchedules } from "@modules/schedules/store/schedule.actions";
import { AppState } from "@modules/routes/store/route.state";
import { selectAllRoutesDisplay } from "@modules/routes/store/route-details.selector";
import { ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { getSelectedCompany } from "@modules/organization/store/company/company.selector";
import { CompaniesState } from "@modules/organization/store/company/company.state";
const $ = require("jquery");

@Component({
  selector: "app-table-devices",
  templateUrl: "./table-devices.component.html",
  styleUrls: ["../../containers/devices/devices.component.scss"],
})
export class TableDevicesComponent implements OnInit {
  [x: string]: any;
  @Output() AddPositionToDisplayListModal = new EventEmitter<Device>();
  @Output() RemovePositionFromDisplayListModal = new EventEmitter<Device>();

  @Output() deviceIdEmit = new EventEmitter<Number>();
  @Output() getFilterDevice = new EventEmitter<any>();

  @Input() isSmall: Boolean = false;

  displayedColumns: any = [
    "check",
    "cd",
    "name",
    "status",
    "company_name",
    "actions",
  ];
  _dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() pageSizeOptions: number = 10;

  // add Sort
  sortedDirection: any;
  sortedColumn!: string;
  selectedDriverId!: any;

  listDevices$ = this.store.pipe(select(selectAllDevices));
  listDevices: any;
  AllDevices!: Device[];

  DisplayedPositions$ = this.store.pipe(select(getDisplayedPositionsList));
  DisplayedPositions: any;

  listSchedules$ = this.storeSchedules.pipe(select(selectAllSchedules));
  routesDisplay$ = this.storeRoute.pipe(select(selectAllRoutesDisplay));

  selectedCompany$ = this.storeCompany.pipe(select(getSelectedCompany));
  selectedCompany: any;

  top = 0;

  constructor(
    private store: Store<DevicesState>,
    private storeSchedules: Store<SchedulesState>,
    private storeRoute: Store<AppState>,
    public modalService: NgbModal,
    public authService: AuthService,
    private storeCompany: Store<CompaniesState>,
    public permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    // if (!this.isSmall) {
    //   this.displayedColumns = this.displayedColumns.filter(
    //     (item: any) => item != "check"
    //   );
    // }

    // if (!this.permissionService.checkPermission(this.authService.getUserInfo()))
    //   this.displayedColumns = this.displayedColumns.filter(
    //     (item: any) => item != "actions"
    //   );

    this.applyFilter(null, false);
    this.selectedCompany$.subscribe((data: any) => {
      this.selectedCompany = data;
      this.applyFilter(data, false);
    });

    this.loadDisplayedStations();

    $(document).ready(() => {
      // let topState = (document.getElementById("top-device") as HTMLElement).clientHeight;
      // $(".body-device").css("top", '115px');
      this.top = this.isSmall == true ? 115 : 0;
    });

    this.storeSchedules.dispatch(LoadAllSchedules());
    this.listSchedules$.subscribe((data: any) => {
      if (data && data.length > 0) {
        let tt = data.schedules;
      }
    });
  }

  ngAfterViewInit() {
    const paginatorIntl = this.paginator?._intl;
    paginatorIntl.nextPageLabel = "";
    paginatorIntl.previousPageLabel = "";
    paginatorIntl.itemsPerPageLabel = "";
    paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 of ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} (${length})`;
    };

    // hide itemsPerPage number
    $(".mat-paginator-page-size").hide();

    this._dataSource = new MatTableDataSource(this.listDevices);
    this._dataSource.paginator = this.paginator;
    this._dataSource.sort = this.sort;
    this.AllDevices = this.listDevices;
  }

  loadDisplayedStations() {
    this.DisplayedPositions$.subscribe((positions) => {
      this.DisplayedPositions = positions;
    });
  }
  //filter array of object exist in another array of object by key and value of object in array
  filterArrayObjectExistInAnotherArrayObjectByKeyAndValue(
    arrayObject: any,
    arrayObjectFilter: any,
    key1: string,
    key2: string
  ) {
    return arrayObject.filter((item: any) =>
      arrayObjectFilter.some((item2: any) => item[key1] === item2[key2])
    );
  }

  currentFilterTable: string = "";
  // add Filter
  @ViewChild("inputSearch") inputSearch!: ElementRef;
  applyFilter(company: any = null, keuUp: boolean = true) {
    // this.currentFilterTable = filterValue;
    this.store.dispatch(LoadAllDevices());

    if (this.isSmall) {
      this.routesDisplay$.subscribe((dataRoute: any) => {
        this.listSchedules$.subscribe((dataSchedules: any) => {
          if (dataSchedules && dataSchedules.schedules.length > 0) {
            this.listDevices$.subscribe((data: any) => {
              // this.listDevices = filterMultipleKeys(
              //   data?.devices,
              //   ["status"],
              //   "online"
              // );
              if (data && data.devices.length > 0) {
                this.listDevices = data?.devices;
                let ObjFilter = dataSchedules.schedules;
                if (dataRoute && dataRoute.length > 0) {
                  ObjFilter = this.filterArrayObjectExistInAnotherArrayObjectByKeyAndValue(
                    dataSchedules.schedules,
                    dataRoute,
                    "route_id",
                    "route_id"
                  );
                }
                this.listDevices = this.filterArrayObjectExistInAnotherArrayObjectByKeyAndValue(
                  this.listDevices,
                  ObjFilter,
                  "id",
                  "device_id"
                );

                //filter value is the value of the input
                if (
                  !isNullOrEmpty(this.inputSearch?.nativeElement.value.trim())
                ) {
                  this.listDevices = filterMultipleKeys(
                    this.listDevices,
                    ["cd", "name", "status", "company_name"],
                    this.inputSearch?.nativeElement.value.trim()
                  );
                }

                if (keuUp) {
                  this.isCheckedAll = false;
                }

                if (
                  typeof this.sortedColumn == "undefined" ||
                  this.sortedColumn == null ||
                  typeof this.sortedDirection == "undefined" ||
                  this.sortedDirection == null
                ) {
                  this.listDevices = sort(this.listDevices, "cd", "asc");
                } else {
                  this.onSort({
                    column: this.sortedColumn,
                    direction: this.sortedDirection,
                  });
                }

                if (company != null && company?.id != -1) {
                  this.listDevices = this.listDevices.filter(
                    (item: any) => item.companyId == company.id
                  );
                }
                this._dataSource = new MatTableDataSource(this.listDevices);
                this._dataSource.paginator = this.paginator;
                this._dataSource.sort = this.sort;

                this.getFilterDevice.emit(this.listDevices);
                this.AllDevices = this.listDevices;
              }
            });
          }
        });
      });
    } else {
      this.listDevices$.subscribe((data: any) => {
        if (data && data.devices.length > 0) {
          this.listDevices = data?.devices;

          //filter value is the value of the input
          if (!isNullOrEmpty(this.inputSearch?.nativeElement.value.trim())) {
            this.listDevices = filterMultipleKeys(
              this.listDevices,
              ["cd", "name", "status", "company_name"],
              this.inputSearch?.nativeElement.value.trim()
            );
          }

          if (keuUp) {
            this.isCheckedAll = false;
          }

          if (
            typeof this.sortedColumn == "undefined" ||
            this.sortedColumn == null ||
            typeof this.sortedDirection == "undefined" ||
            this.sortedDirection == null
          ) {
            this.listDevices = sort(this.listDevices, "cd", "asc");
          } else {
            this.onSort({
              column: this.sortedColumn,
              direction: this.sortedDirection,
            });
          }

          if (company != null && company?.id != -1) {
            this.listDevices = this.listDevices.filter(
              (item: any) => item.companyId == company.id
            );
          }

          this._dataSource = new MatTableDataSource(this.listDevices);
          this._dataSource.paginator = this.paginator;
          this._dataSource.sort = this.sort;

          this.getFilterDevice.emit(this.listDevices);
          this.AllDevices = this.listDevices;
        }
      });
    }
  }

  // applyFilter(filterValue: string, keuUp: boolean = true) {
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this._dataSource.filter = filterValue;

  //   if (
  //     typeof this.sortedColumn == "undefined" ||
  //     this.sortedColumn == null ||
  //     typeof this.sortedDirection == "undefined" ||
  //     this.sortedDirection == null
  //   ) {
  //     this.listDevices = sort(this.listDevices, "cd", "asc");
  //   } else {
  //     this.onSort({
  //       column: this.sortedColumn,
  //       direction: this.sortedDirection,
  //     });
  //   }

  //   if (keuUp) {
  //     this.isCheckedAll = false;
  //   }

  //   this.getFilterDevice.emit(this.listDevices);
  // }

  //quynh-nta adding 2022/08/25==================
  openAddDevicesModalClick() {
    const modalRef = this.modalService.open(ModalAddDevicesComponent, {
      size: "sm",
      backdrop: false,
      centered: true,
      keyboard: false,
    });

    let cd = "";
    if (this.AllDevices && this.AllDevices.length > 0) {
      let device = getLimit1SortDescByColumn(this.AllDevices, "cd");
      if (device) {
        cd = device.cd;
      }
    } else {
      cd = "DEV0";
    }

    //cut string and add 1 and lenght 8
    let cdNew =
      cd.substring(0, 3) +
      (parseInt(cd.substring(3, cd.length)) + 1).toString().padStart(5, "0");

    modalRef.componentInstance.cd = cdNew;
    modalRef.componentInstance.devices = this.AllDevices;
    modalRef.componentInstance.company = this.selectedCompany;
  }

  openUpdateDeviceModalClick(device: Device) {
    const modalRef = this.modalService.open(ModalUpdateDevicesComponent, {
      size: "sm",
      backdrop: false,
      centered: true,
      keyboard: false,
    });

    modalRef.componentInstance.deviceFocus = device;
    modalRef.componentInstance.devices = this.AllDevices;
  }

  openConfirmDelDeviceModalClick(device: Device) {
    const modalRef = this.modalService.open(ModalDeleteDevicesComponent, {
      size: "sm",
      backdrop: false,
      centered: true,
      keyboard: false,
    });
    modalRef.componentInstance.deviceDel = device;
  }
  //==================

  onSort({ column, direction }: SortEvent) {
    // for show UI sort
    this.sortedColumn = column;
    this.sortedDirection = direction;

    //for logic sort
    if (this.listDevices)
      this.listDevices = sort(this.listDevices, column, direction);
  }

  highlight(device_id: number) {
    this.store.dispatch(SelectDevice({ deviceId: device_id }));

    this.selectedDriverId = device_id;
  }

  onShowCheckboxChange(values: any, device: Device) {
    let deviceId = device.id;
    if (values.currentTarget.checked) {
      return this.store.dispatch(AddPositionToDisplayList({ deviceId }));
    } else {
      this.isCheckedAll = false;
      return this.store.dispatch(RemovePositionFromDisplayList({ deviceId }));
    }
  }

  isCheckedAll: boolean = false;
  onCheckAllChange(event: any) {
    this.isCheckedAll = event.currentTarget.checked;

    if (event.target.checked) {
      this.listDevices.forEach((device: Device) => {
        let deviceId = device.id;
        this.store.dispatch(AddPositionToDisplayList({ deviceId }));
      });
    } else {
      this.listDevices.forEach((device: Device) => {
        let deviceId = device.id;
        this.store.dispatch(RemovePositionFromDisplayList({ deviceId }));
      });
    }
  }

  isChecked(device: Device) {
    if (this.DisplayedPositions) {
      return (
        filterByKey(this.DisplayedPositions, "deviceId", device.id?.toString())
          .length > 0
      );
    } else {
      return false;
    }
  }

  @Output() isShowDeviceName = new EventEmitter<boolean>();
  onCheckDeviceNameDisplay(event: any) {
    if (event.target.checked) {
      this.isShowDeviceName.emit(true);
      this.store.dispatch(ToggleDeviceName({ isShow: true }));
    } else {
      this.isShowDeviceName.emit(false);
      this.store.dispatch(ToggleDeviceName({ isShow: false }));
    }
  }
}
