import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanActivateGuardedRoute } from "@common/shared/guards";
export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "dashboard",
  },
  {
    path: "confirm-password",
    loadChildren: () =>
      import("../modules/auth/confirm-password/confirm-password.module").then(
        (m) => m.ConfirmPasswordModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../modules/dashboards/dashboards-routing.module").then(
        (m) => m.DashboardsRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "users",
    loadChildren: () =>
      import("../modules/users/users.module").then((m) => m.UsersModule),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "error",
    loadChildren: () =>
      import("modules/error/error-routing.module").then(
        (m) => m.ErrorRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },

  //adding
  {
    path: "login",
    loadChildren: () =>
      import("@modules/auth/login/login.module").then((m) => m.LoginModule),
  },

  {
    path: "forgot-password",
    loadChildren: () =>
      import("@modules/auth/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },

  {
    path: "register",
    loadChildren: () =>
      import("@modules/auth/register/register.module").then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("../modules/auth/login/login.module").then((m) => m.LoginModule),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "map",
    loadChildren: () =>
      import("../modules/map/map-routing.module").then(
        (m) => m.MapRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "chat",
    loadChildren: () =>
      import("../modules/chat/chat.module").then((m) => m.ChatModule),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "camera",
    loadChildren: () =>
      import("../modules/camera/camera.module").then((m) => m.CameraModule),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "drivers",
    loadChildren: () =>
      import("../modules/drivers/drivers-routing.module").then(
        (m) => m.DriversRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "devices",
    loadChildren: () =>
      import("../modules/devices/devices-routing.module").then(
        (m) => m.DevicesRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "vehicles",
    loadChildren: () =>
      import("../modules/vehicles/vehicles-routing.module").then(
        (m) => m.VehiclesRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "schedules",
    loadChildren: () =>
      import("../modules/schedules/schedules-routing.module").then(
        (m) => m.SchedulesRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "routes",
    loadChildren: () =>
      import("../modules/routes/routes-routing.module").then(
        (m) => m.RoutesRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "actuals",
    loadChildren: () =>
      import("../modules/actuals/actuals-routing.module").then(
        (m) => m.ActualsRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "stations",
    loadChildren: () =>
      import("../modules/stations/stations-routing.module").then(
        (m) => m.StationsRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "timetable",
    loadChildren: () =>
      import("../modules/timetable/table-time-table-routing.module").then(
        (m) => m.TableTimeTableRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "services",
    loadChildren: () =>
      import("../modules/services/services-routing.module").then(
        (m) => m.ServicesRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "system",
    loadChildren: () =>
      import("../modules/system/system-routing.module").then(
        (m) => m.SystemRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "organization",
    loadChildren: () =>
      import("../modules/organization/organization-routing.module").then(
        (m) => m.OrganizationRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: ":website",
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("../modules/dashboards/dashboards-routing.module").then(
            (m) => m.DashboardsRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "users",
        loadChildren: () =>
          import("../modules/users/users.module").then((m) => m.UsersModule),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "chat",
        loadChildren: () =>
          import("../modules/chat/chat.module").then((m) => m.ChatModule),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "camera",
        loadChildren: () =>
          import("../modules/camera/camera.module").then((m) => m.CameraModule),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "drivers",
        loadChildren: () =>
          import("../modules/drivers/drivers-routing.module").then(
            (m) => m.DriversRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "devices",
        loadChildren: () =>
          import("../modules/devices/devices-routing.module").then(
            (m) => m.DevicesRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "vehicles",
        loadChildren: () =>
          import("../modules/vehicles/vehicles-routing.module").then(
            (m) => m.VehiclesRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "schedules",
        loadChildren: () =>
          import("../modules/schedules/schedules-routing.module").then(
            (m) => m.SchedulesRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "routes",
        loadChildren: () =>
          import("../modules/routes/routes-routing.module").then(
            (m) => m.RoutesRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "actuals",
        loadChildren: () =>
          import("../modules/actuals/actuals-routing.module").then(
            (m) => m.ActualsRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "stations",
        loadChildren: () =>
          import("../modules/stations/stations-routing.module").then(
            (m) => m.StationsRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "timetable",
        loadChildren: () =>
          import("../modules/timetable/table-time-table-routing.module").then(
            (m) => m.TableTimeTableRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "services",
        loadChildren: () =>
          import("../modules/services/services-routing.module").then(
            (m) => m.ServicesRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "system",
        loadChildren: () =>
          import("../modules/system/system-routing.module").then(
            (m) => m.SystemRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
      {
        path: "organization",
        loadChildren: () =>
          import("../modules/organization/organization-routing.module").then(
            (m) => m.OrganizationRoutingModule
          ),
        canActivate: [CanActivateGuardedRoute],
      },
    ],
  },
  {
    path: "version",
    loadChildren: () =>
      import("modules/utility/utility-routing.module").then(
        (m) => m.UtilityRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
  {
    path: "**",
    pathMatch: "full",
    loadChildren: () =>
      import("../modules/dashboards/dashboards-routing.module").then(
        (m) => m.DashboardsRoutingModule
      ),
    canActivate: [CanActivateGuardedRoute],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
