import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class IconService {
  files: any;
  constructor(private http: HttpClient) {}

  getData() {
    return this.http.get('/assets/icon.json');
  }
}