import { Partner } from "@modules/organization/models/Partner";
import { ResUser } from "@modules/users/models/ResUser";
import { User } from "@modules/users/models/user";
import { createReducer, on } from "@ngrx/store";

import {
  LoadAllUsersSuccess,
  LoadAllUsersFailure,
  AddUserSuccess,
  AddUserFailure,
  UpdateUserSuccess,
  UpdateUserFailure,
  DeleteUserSuccess,
  DeleteUserFailure,
  LoadAllRolesSuccess,
  LoadAllRolesFailure,
  AddResUserSuccess,
  AddResUserFailure,
  UpdateResUserSuccess,
  UpdateResUserFailure,
  DeleteResUserSuccess,
  DeleteResUserFailure,
  GetConfirmPasswordSuccess,
  GetConfirmPasswordFailure,
  UpdatePasswordSuccess,
  UpdatePasswordFailure,
  ResetUser,
  ResetError,
} from "../actions/user.actions";

export interface UsersState {
  users: User[] | [];
  resUser: ResUser[] | [];
  errorMessage: string | null;
  roles: any[] | [];
  infoConfirmPassword: any;
  user: any;
}

export const initialState: UsersState = {
  users: [],
  resUser: [],
  errorMessage: null,
  roles: [],
  infoConfirmPassword: "",
  user: "",
};

const _usersReducer = createReducer(
  initialState,
  on(LoadAllRolesSuccess, (state, { roles }) => ({
    ...state,
    roles: roles,
  })),
  on(LoadAllRolesFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),

  on(LoadAllUsersSuccess, (state, { users }) => ({
    ...state,
    users: users,
  })),
  on(LoadAllUsersFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),

  on(AddUserSuccess, (state, { user }) => ({
    ...state,
    users: [...state.users, user],
    errorMessage: '',
  })),
  on(AddUserFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),
  //=========================================
  on(UpdateUserSuccess, (state, { user }) => ({
    ...state,
    users: [
      ...state.users.filter((currentItem: User) => currentItem.id !== user.id),
      user,
    ],
    errorMessage: "",
  })),

  on(UpdateUserFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),

  on(DeleteUserSuccess, (state, { userId }) => ({
    ...state,
    users: state.users.filter((currentItem: User) => currentItem.id !== userId),
    errorMessage: null,
  })),
  on(DeleteUserFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),

  on(AddResUserSuccess, (state, { resUser }) => ({
    ...state,
    resUser: [...state.resUser, resUser],
    errorMessage: "",
  })),
  on(AddResUserFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),
  on(UpdateResUserSuccess, (state, { resUser }) => ({
    ...state,
    resUser: [
      ...state.resUser.filter(
        (currentItem: ResUser) => currentItem.id !== resUser.id
      ),
      resUser,
    ],
    errorMessage: "",
  })),
  on(UpdateResUserFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),

  on(DeleteResUserSuccess, (state, { userId }) => ({
    ...state,
    errorMessage: null,
  })),
  on(DeleteResUserFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),
  //getConfirmPassword
  on(GetConfirmPasswordSuccess, (state, { response }) => ({
    ...state,
    infoConfirmPassword: response,
  })),
  on(GetConfirmPasswordFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),
  //UPDATE_PASSWORD
  on(UpdatePasswordSuccess, (state, { response }) => ({
    ...state,
    user: response,
  })),
  on(UpdatePasswordFailure, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),

  on(ResetUser, (state) => ({
    ...state,
    user: "",
  })),
  on(ResetError, (state) => ({
    ...state,
    errorMessage: null,
  }))
);

export function reducer(state: UsersState | undefined, action: any) {
  return _usersReducer(state, action);
}
