import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import config from "../../app-common/shared/config/config";
import { selectAllCompanies } from "@modules/organization/store/company/company.selector";
import { CompaniesState } from "@modules/organization/store/company/company.state";
import { select, Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  [x: string]: any;
  private BACKEND_URL = config.BACKEND_URL;

  $master_Companies = this.storeCompany.pipe(select(selectAllCompanies));
  master_Companies: Record<string, any>[] = [];

  constructor(
    private http: HttpClient,
    private storeCompany: Store<CompaniesState>,
    private router: ActivatedRoute
  ) {}

  getUserInfo(): any {
    const userInfoStr = localStorage.getItem("user");
    if (!userInfoStr || userInfoStr.length === 0) return {} as any;
    const userInfoJson = JSON.parse(userInfoStr);

    return userInfoJson;
  }

  isAuthenticated(): boolean {
    return this.getUserInfo() !== null && this.getUserInfo().id !== undefined;
  }

  saveUserInfo(user: any): void {
    let userUpd = user;
    if (user.permission.split(", ").includes("10")) {
      userUpd = {
        ...user,
        company_id: -1,
        id_company: user.company_id,
        company_name: "All",
      };
    }
    localStorage.setItem("user", JSON.stringify(userUpd));
  }

  updateUserInfo(company_id: number): void {
    // just change company_id
    const updatedUserInfo = {
      ...this.getUserInfo(),
      company_id: company_id,
    };

    this.saveUserInfo(updatedUserInfo);
  }

  removeUserInfo(): void {
    localStorage.removeItem("user");
  }

  logIn(email: string, password: string): Observable<any> {
    const body = new HttpParams().set("email", email).set("password", password);
    return this.http.post(`${this.BACKEND_URL}/session`, body);
  }

  logout(): Observable<any> {
    return this.http.delete(`${this.BACKEND_URL}/session`);
  }
}
